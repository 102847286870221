<template>
  <div class="page df fdc" :class="{ 'jc-sb': !isDaDiSign }">
    <div>
      <!-- S 背景图 -->
      <img v-if="login_img" class="img-bg" :src="login_img" alt />
      <img
        v-else-if="!login_img && img_request"
        class="img-bg"
        src="../../assets/img_denglu_bg@3x.png"
        alt
      />
      <!-- E 背景图 -->

      <!-- S tab -->
      <ul
        class="tab-list"
        v-if="
          registerStatus == 1 &&
          businessType != 3 &&
          !isDaDiSign &&
          !isChaoWei &&
          !isJyFl
        "
      >
        <li :class="{ active: $route.name === 'login' }">
          <router-link to="login" tag="div" class="btn login-btn flex-center"
            >登录</router-link
          >
        </li>
        <li
          :class="{
            active:
              $route.name === 'register' ||
              $route.name === 'register_set_password',
          }"
        >
          <router-link
            to="register"
            tag="div"
            class="btn register-btn flex-center"
            >注册</router-link
          >
        </li>
      </ul>
      <ul class="tab-list" v-else></ul>
      <!-- E tab -->

      <h3 v-if="!isDaDiSign" class="title">Hi，请登录</h3>

      <!-- S 表单 -->
      <div v-if="!isDaDiSign" class="form">
        <div v-if="customAccount || pointsTopic" class="cell">
          <i class="icon icon-mobile"></i>
          <input type="number" v-model="seaName" :placeholder="accountTxt" />
        </div>
        <div v-else class="cell">
          <i class="icon icon-mobile"></i>
          <input
            type="number"
            v-model="mobile"
            :placeholder="accountTxt"
            maxlength="11"
          />
        </div>
        <div class="cell" v-if="loginStatus">
          <i class="icon icon-password"></i>
          <input
            type="password"
            v-model="password"
            placeholder="请输入账户密码"
          />
        </div>
        <div v-else>
          <div v-if="!isDaDiSign" class="cell">
            <i class="icon icon-img-code"></i>
            <input
              type="text"
              v-model="code"
              maxlength="6"
              placeholder="请输入验证码"
            />
            <div class="img-box" @click.prevent="getRandomImgCode">
              <img :src="img_code" class="img-code" />
            </div>
          </div>
          <div v-if="!isDaDiSign" class="cell">
            <i class="icon icon-sms-code"></i>
            <input
              type="number"
              v-model="sms_code"
              maxlength="6"
              placeholder="请输入短信验证码"
            />
            <div
              class="btn send-btn flex-center"
              v-show="code_bool"
              @click.prevent="getSmsCode"
            >
              发送验证码
            </div>
            <div class="countdown flex-center" v-show="!code_bool">
              {{ count }}s
            </div>
          </div>
        </div>
      </div>
      <!-- E 表单 -->

      <!-- S 忘记密码 -->
      <div v-if="!isDaDiSign && !isChaoWei && !isJyFl" class="form-bottom">
        <!-- <div v-if="!isDaDiSign && !isChaoWei" class="form-bottom"> -->
        <!-- <p v-if="btnStatus" @click="loginType" :style="txtColor ? 'color:' + txtColor : 'color:#6c57ff'">{{ loginStatus ? "手机快捷登录" : "账号登录" }}</p> -->
        <p v-if="btnStatus" @click="loginType" class="login-status">
          {{ loginStatus ? "手机快捷登录" : "账号登录" }}
        </p>
        <router-link
          v-if="loginStatus && !customAccount && !pointsTopic"
          to="forget_password"
          class="link forget-pwd-link"
          >忘记密码？</router-link
        >
      </div>
      <!-- E 忘记密码 -->
    </div>

    <div>
      <!-- S 登录按钮 -->
      <div v-if="!isDaDiSign">
        <div
          class="btn submit-btn flex-center"
          v-if="!is_request"
          @click="pointsTopic || customAccount ? topicSubmit() : submit()"
        >
          立即登录
        </div>
        <div class="btn submit-btn flex-center" v-else>立即登录</div>
      </div>
      <div
        class="btn submit-btn flex-center wx-btn"
        :class="{ 'dadi-shape': isDaDiSign }"
        v-if="wxStatus && !isChaoWei && !isJyFl"
        @click="wxLogin"
      >
        微信登录
      </div>
      <!-- E 登录按钮 -->
      <!-- S 底部文案-->
      <p class="login-text">{{ login_text }}</p>
      <!-- E 底部文案-->
    </div>

    <!-- S 提示弹窗 -->
    <alert-tip
      :type="type"
      :show-alert="show_tip"
      :alert-text="tip"
      @changeShowAlert="changeShow"
    ></alert-tip>
    <!-- E 提示弹窗 -->
    <div style="text-align: center" v-if="isChaoWei">
      请使用《电子质保卡系统》账号密码登录
    </div>
  </div>
</template>

<script>
import Validator from "@/utils/validator";
import AlertTip from "@/components/common/AlertTip";
import { toolTip, inputMixins } from "@/mixins";
import { debounce } from "@/utils/debounce";
import { mapMutations, mapState } from "vuex";
import md5 from "js-md5";
import setRsaPass from "@/utils/jsencrypt";
import {
  login,
  login2,
  login3,
  codeLogin,
  getColor,
  getImgCode,
  sendVerifyCode,
} from "@/services";
import { getAppId, getLoginConfig } from "@/services/user";
import { tripartite, customAccount } from "@/utils/custom";

let base_code = "";
const COMPUTED_TIME = 60;
export default {
  name: "Login",
  components: {
    AlertTip,
  },
  mixins: [toolTip, inputMixins],
  data() {
    return {
      wxStatus: false, //微信登录是否开启
      loginStatus: true, //登录方式
      btnStatus: false, //切换登录方式按钮
      mobile: "", // 手机号
      password: "", // 密码
      is_request: false,
      login_img: "", //登录图
      img_request: false,
      login_text: "", //登录文案
      code: "", // 验证码
      sms_code: "", // 短信验证码
      img_code: "", // 图形验证码
      code_bool: true, // 控制显示获取验证码
      count: "", // 倒计时
      timer: null, // 计时器
      txtColor: null, //字体颜色,
      isDaDiSign: false, //  是否是大地保险签到银行
      customAccount: false, //  是否是定制账号登录
      pointsTopic: false, //  定制积分专题
      accountTxt: "请输入你的手机号", //  账号文案
      seaName: "", //  海元素定制商城
      isChaoWei: false, //超威商城
      isJyFl: false, //江阴福利
    };
  },
  watch: {
    // 当清空账号时，清空密码
    seaName: function (val) {
      if (val === "") {
        this.password = "";
      }
    },
  },
  computed: {
    ...mapState({
      businessType: (state) => state.businessInfo.type, //1现金商城，2积分商品，3内采商城
      userInfo: (state) => state.userInfo, //用户信息
      registerStatus: (state) => state.config.register_status, // 注册开关,1开启，0关闭
    }),
  },
  methods: {
    ...mapMutations(["getUser", "getSessionId"]),
    //切换登录状态
    loginType() {
      if (this.loginStatus) {
        this.loadImgCode();
      }
      this.loginStatus = !this.loginStatus;
    },

    // 点击登录按钮
    topicSubmit: debounce(async function () {
      let password = this.password.replace(/\s+/g, "");
      if (!this.seaName) {
        this.show_layer(
          "cancel",
          this.pointTopic ? "请输入客户号" : "请输入账号"
        );
        this.is_request = false;
        return;
      }

      if (!password) {
        this.show_layer("cancel", "请输入密码");
        this.is_request = false;
        return;
      }
      try {
        let res = null;
        let newPass;
        if (customAccount("VUE_APP_CHAOWEI")) {
          newPass = password;
        } else {
          newPass = md5(password);
        }
        res = await login3({ username: this.seaName, password: newPass });
        console.log({ res });
        this.is_request = false;
        if (res.code === 0) {
          const { PHPSESSID, user } = res.data;
          const show = res.data.is_show;
          if (show) {
            sessionStorage.setItem("show", show);
          } else {
            sessionStorage.setItem("show", "");
          }
          // eslint-disable-next-line no-undef
          SID = PHPSESSID;
          this.getSessionId(PHPSESSID);
          this.show_layer("success", "登录成功");
          this.$store.dispatch("getUserInfo");
          await this.getUser(user);
          await this.getThemeColor();
          try {
            const data = await this.$store.dispatch("getBusinessRate");
            if (JSON.stringify(data) !== "{}") {
              this.$router.push(sessionStorage.route || "home");
            }
          } catch (e) {
            console.log(e);
          }
        } else {
          this.show_layer("cancel", res.msg);
        }
      } catch (error) {
        console.warn("Something bad happened in api login: ", error);
      }
    }, 300),

    // 点击登录按钮
    submit: debounce(async function () {
      console.log(11111111);
      /* if (this.loginStatus) {
      	this.pswLogin();
      } else{
      	this.toCodeLogin();
      } */
      this.is_request = true;
      let mobile = this.mobile.replace(/\s+/g, ""),
        password = this.password.replace(/\s+/g, ""),
        sms_code = this.sms_code.replace(/\s+/g, "");
      const { result, errorMessage } = Validator.validate("mobile", mobile);
      if (!mobile) {
        this.show_layer("cancel", "请输入账号");
        this.is_request = false;
        return;
      }
      if (!result && !this.customAccount) {
        this.show_layer("cancel", errorMessage);
        this.is_request = false;
        return;
      }
      if (this.loginStatus ? !password : !sms_code) {
        this.show_layer(
          "cancel",
          this.loginStatus ? "请输入密码" : "请输入短信验证码"
        );
        this.is_request = false;
        return;
      }
      try {
        let res = null;
        if (!this.customAccount) {
          if (this.businessType === 3) {
            this.loginStatus
              ? (res = await login(mobile, md5(password)))
              : (res = await codeLogin(mobile, sms_code));
          } else {
            this.loginStatus
              ? (res = await login2(
                  mobile,
                  this.isJyFl ? setRsaPass(md5(password)) : md5(password)
                ))
              : (res = await codeLogin(
                  mobile,
                  this.isJyFl ? setRsaPass(sms_code) : sms_code
                ));
          }
        } else {
          if (customAccount("VUE_APP_CHAOWEI")) {
            res = await login3(mobile, password); //超威定制商城 去掉md5加密
          } else {
            res = await login3(mobile, md5(password));
          }

          console.log({ res });
        }
        this.is_request = false;
        if (res.code === 0) {
          const { PHPSESSID, user } = res.data;
          const show = res.data.is_show;
          if (show) {
            sessionStorage.setItem("show", show);
          } else {
            sessionStorage.setItem("show", "");
          }
          // eslint-disable-next-line no-undef
          SID = PHPSESSID;
          this.getSessionId(PHPSESSID);
          this.show_layer("success", "登录成功");
          this.$store.dispatch("getUserInfo");
          await this.getUser(user);
          await this.getThemeColor();
          try {
            const data = await this.$store.dispatch("getBusinessRate");
            if (JSON.stringify(data) !== "{}") {
              this.$router.replace(sessionStorage.route || "home");
            }
          } catch (e) {
            console.log(e);
          }
        } else {
          this.show_layer("cancel", res.msg);
        }
      } catch (error) {
        console.warn("Something bad happened in api login: ", error);
      }
    }, 300),

    //微信登录
    wxLogin: debounce(async function () {
      let url = `${process.env.VUE_APP_BASE_API}/?#/binding`;
      let newUrl = encodeURIComponent(url);

      try {
        const platformAppIds = [
          "wx83b07423b2042955", // 壹企通测试（开发&测试）
          "wx0e0471eddfe45280", // 壹企通科技（预发&正式）
        ];
        const res = await getAppId();
        if (res.code === 0) {
          let appId = res.msg;
          let host = "https://open.weixin.qq.com/connect/oauth2/authorize";
          if (platformAppIds.includes(appId)) {
            host = "https://auth.yqtbuy.com/srv/proxy/wechat/authorize";
          }
          // 跳转至统一代理进行网页授权
          window.location.href = `${host}?appid=${appId}&redirect_uri=${newUrl}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
        }
      } catch (error) {
        throw error;
      }
    }, 300),

    // 切换图形验证码
    getRandomImgCode: debounce(function () {
      this.img_code = base_code + "&" + Math.random();
    }, 300),

    // 获取短信验证码
    getSmsCode: debounce(async function () {
      let mobile = this.mobile.replace(/\s+/g, ""),
        code = this.code.replace(/\s+/g, "");
      const { result, errorMessage } = Validator.validate("mobile", mobile);
      if (!mobile) {
        this.show_layer("cancel", "请输入手机号码");
        return;
      }
      if (!result) {
        this.show_layer("cancel", errorMessage);
        return;
      }
      if (!code) {
        this.show_layer("cancel", "请输入图形验证码");
        return;
      }
      if (this.timer) {
        clearInterval(this.timer);
      }
      const res = await sendVerifyCode(mobile, "login", code);
      if (res.code === 0) {
        this.show_layer("success", "验证码已发送，请注意查收");
        this.count = COMPUTED_TIME;
        this.code_bool = false;
        this.timer = setInterval(() => {
          if (this.count > 0) {
            this.count--;
          } else {
            this.code_bool = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      } else {
        this.show_layer("cancel", res.msg);
      }
    }, 300),

    // 获取主题色
    async getThemeColor() {
      try {
        const res = await getColor(this.$store.state.userInfo.business_id);
        this.img_request = true;
        if (res.code === 0) {
          const { color, login_img, login_text } = res.data;

          console.log(login_img);
          this.login_img = login_img;
          this.login_text = login_text;
          this.txtColor = color;
          document.body.style.setProperty("--main-color", color);
          this.$forceUpdate();
        }
      } catch (error) {
        console.warn("Something bad happened in api getColor: ", error);
      }
    },

    //获取登录方式配置
    async getLoginConfig() {
      try {
        const res = await getLoginConfig();
        if (res.code === 0) {
          let data = res.data;
          for (let item of data) {
            if (item.name === "login_type") {
              /* if (item.value === "0") {
							this.loginStatus = true;
						} else{
							this.loginStatus = false;
							this.loadImgCode();
						} */
              if (item.value === "1") {
                this.btnStatus = true;
              }
            } else {
              if (item.value === "0") {
                this.wxStatus = false;
              } else {
                this.businessType === 3
                  ? (this.wxStatus = false)
                  : (this.wxStatus = true);
              }
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    },

    // 获取图形验证码
    async loadImgCode() {
      const res = await getImgCode();
      if (res.code === 0) {
        this.img_code = res.data.url;
        base_code = res.data.url;
      } else {
        if (process.env.NODE_ENV === "development") {
          this.show_layer("cancel", res.error_msg);
        }
      }
    },

    //  是否是大地保险签到商城
    isDaDiShop() {
      if (tripartite("VUE_APP_DADIBX")) {
        this.isDaDiSign = true;
      }
    },
    //江阴福利
    isjy() {
      if (customAccount("VUE_APP_JY")) {
        this.isJyFl = true;
        this.loginType();
      }
    },
    //  积分专题定制
    isPointTopic() {
      if (customAccount("VUE_APP_POINTS_TOPIC")) {
        this.pointsTopic = true;
        this.accountTxt = "请输入客户号";
        console.log("12", this.pointsTopic);
      }
    },
    //超威电池商城登录
    isChaoWeiF() {
      if (customAccount("VUE_APP_CHAOWEI")) {
        this.accountTxt = "请输入您的账号";
        this.customAccount = true;
        this.isChaoWei = true;
      } else {
        this.isChaoWei = false;
      }
    },
  },
  mounted() {
    this.isDaDiShop();
    document.title = this.$route.meta.title;
    this.getThemeColor();
    this.getLoginConfig();
    this.isjy();
    //console.log('userInfo', this.userInfo);
    if (this.userInfo && this.userInfo.id) {
      this.$router.replace("home");
    }

    if (customAccount("VUE_APP_ACCOUNT")) {
      this.accountTxt = "请输入账号";
      this.customAccount = true;
    }
    this.isChaoWeiF(); //超威电池商城
    this.isPointTopic();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.page {
  .bj(#fff);
  padding-bottom: 0.6rem;
  .img-bg {
    .wh(100%, 3.64rem);
  }

  .tab-list {
    position: relative;
    .wh(100%, 1rem);
    margin-bottom: 0.3rem;
    overflow: hidden;

    li {
      .wh(50%, 100%);
      float: left;
      position: relative;
      overflow: hidden;
      z-index: 0;

      .btn {
        position: relative;
        .wh(100%, 100%);
        .sc(@fontsize-medium, @color-grey);
        line-height: 0.4rem;
        text-align: center;
      }

      &.active {
        .btn {
          .sc(@fontsize-large-x, @color-dark-grey);
          line-height: 0.5rem;
          font-weight: bold;

          &:after {
            content: "";
            .cl;
            bottom: 0;
            display: block;
            .wh(2rem, 0.04rem);
            background-color: var(--main-color);
            border-radius: 0.03rem;
          }
        }
      }
    }
  }

  .title {
    padding-left: 0.52rem;
    //margin-bottom: 0.18rem;
    //.sc(@fontsize-large-xxx, @color-dark-grey);
    //line-height: 0.66rem;
    font-size: 0.4rem;
    color: #000000;
    font-weight: bold;
  }

  .form {
    padding: 0 0.56rem 0 0.54rem;
    margin-bottom: 0.2rem;

    .cell {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      padding-left: 0.52rem;
      padding-top: 0.4rem;
      padding-bottom: 0.3rem;
      border-bottom: 1px solid @color-row-line;

      .icon {
        position: absolute;
        top: 0.44rem;
        left: 0;
        display: inline-block;
        .wh(0.32rem, 0.32rem);
        z-index: 2;
      }

      input {
        .wh(100%, 0.4rem);
        .sc(@fontsize-medium, @color-dark-grey);
        line-height: 0.4rem;

        &::-webkit-input-placeholder {
          color: #b3b3b3;
        }
      }

      .icon-mobile {
        width: 0.24rem;
        .bis("../../assets/icon_denglu_shouji");
      }

      .icon-password {
        width: 0.28rem;
        .bis("../../assets/icon_denglu_mima");
      }

      .icon-img-code {
        height: 0.28rem;
        .bis("../../assets/icon_denglu_yanzhengma");
      }

      .icon-sms-code {
        .bis("../../assets/icon_denglu_shuruyanzhengma");
      }

      .img-box {
        .ct;
        right: 0;
        .wh(2.4rem, 0.8rem);
        z-index: 4;

        .img-code {
          .wh(100%, 100%);
        }
      }

      .send-btn,
      .countdown {
        .ct;
        right: 0;
        .wh(1.68rem, 0.52rem);
        .sc(@fontsize-small, #fff);
        line-height: 0.52rem;
        text-align: center;
        white-space: nowrap;
        border-radius: 0.26rem;
        z-index: 4;
      }

      .send-btn {
        background: var(--main-color);
        .touch-active;
      }

      .countdown {
        background: @color-light-grey;
      }
    }
  }

  .form-bottom {
    position: relative;
    display: flex;
    height: 0.34rem;
    padding-left: 0.56rem;
    padding-right: 0.54rem;
    /*margin-bottom: 1.54rem;*/

    .forget-pwd-link {
      .ct;
      right: 0.54rem;
      .sc(@fontsize-small, @color-dark-grey);
      line-height: 0.34rem;
      white-space: nowrap;
      z-index: 2;
    }

    .login-status {
      background-color: var(--main-color);
      width: 2.8rem;
      height: 0.5rem;
      line-height: 0.5rem;
      color: #ffffff;
      font-size: 0.3rem;
      text-align: center;
      border-radius: 0.5rem;
    }
  }

  .submit-btn {
    .wh(6.4rem, 0.88rem);
    margin: 0 auto;
    .sc(@fontsize-large, #fff);
    line-height: 0.88rem;
    text-align: center;
    white-space: nowrap;
    background-color: var(--main-color);
    border-radius: 0.5rem;
    overflow: hidden;
    .touch-active(var(--main-color));
  }
  .wx-btn {
    background-color: #09bb07;
    margin-top: 10px;
  }
  .login-text {
    text-align: center;
    width: 100%;
    margin-top: 0.3rem;
  }

  .dadi-shape {
    margin-top: 1.5rem;
  }
}
</style>
