<template>
  <div class="page df fdc jc-sb">
    <div>
      <!-- S 背景图 -->
      <img v-if="login_img" class="img-bg" :src="login_img" alt="">
      <img v-else-if="!login_img&&img_request" class="img-bg" src="../../assets/img_denglu_bg@3x.png" alt="">
      <!-- E 背景图 -->

      <!-- S tab -->
      <ul class="tab-list">
        <li :class="{active: $route.name==='login'}">
          <router-link to="login" tag="div" class="btn login-btn flex-center">登录</router-link>
        </li>
        <li :class="{active: $route.name==='register' || $route.name==='register_set_password'}">
          <router-link to="register" tag="div" class="btn register-btn flex-center">注册</router-link>
        </li>
      </ul>
      <!-- E tab -->

      <h3 class="title">Hi，请注册账号</h3>

      <!-- S 表单 -->
      <div class="form">
        <div class="form-group border-bottom-1px">
          <label for="mobile" class="form-label">
            <i class="icon icon-mobile"></i>
          </label>
          <input
              type="text"
              pattern="\d*"
              required
              v-model="mobile"
              id="mobile"
              class="form-control"
              maxlength="11"
              placeholder="请输入手机号码"
          >
        </div>
        <div class="form-group border-bottom-1px">
          <label for="code" class="form-label">
            <i class="icon icon-img-code"></i>
          </label>
          <input
              type="text"
              required
              v-model="code"
              id="code"
              class="form-control"
              maxlength="6"
              placeholder="请输入验证码"
          >
          <div class="img-box" @click.prevent="getRandomImgCode">
            <img :src="img_code" class="img-code">
          </div>
        </div>
        <div class="form-group border-bottom-1px">
          <label for="code" class="form-label">
            <i class="icon icon-sms-code"></i>
          </label>
          <input
              type="text"
              required
              v-model="sms_code"
              id="code"
              class="form-control"
              maxlength="6"
              placeholder="请输入短信验证码"
          >
          <div class="btn send-btn flex-center" v-show="code_bool" @click.prevent="getSmsCode">发送验证码</div>
          <div class="countdown flex-center" v-show="!code_bool">{{count}}s</div>
        </div>
      </div>
      <!-- E 表单 -->

      <!-- S 协议 -->
      <div class="form-bottom">
        <div class="protocol-wrap" @click.stop>
          <div v-if="hasAgree" @click.stop="toggleAgree" class="icon-wrap flex-center">
            <icon-svg class="selected-item main-color" icon-class="yi_icon_xuanze"/>
          </div>
          <div v-else @click.stop="toggleAgree" class="icon-wrap flex-center">
            <icon-svg class="select-item" icon-class="yi_check"/>
          </div>
          <!--<div v-else @click.stop="toggleAgree" class="select-item"></div>-->
          <span class="gray-txt">我已仔细阅读</span>
          <span class="protocol" @click.stop="openProtocolAlert">《用户注册协议》</span>
        </div>
      </div>
      <div class="nickname" v-if="name_show == 1">推广人:{{ name }}</div>
      <!-- E 协议 -->
    </div>
    <div>
      <!-- S 注册按钮 -->
      <div class="btn submit-btn flex-center" v-if="!is_request" @click="submit">立即注册</div>
      <div class="btn submit-btn flex-center" v-else>立即注册</div>
      <!-- E 注册按钮 -->
      <!-- S 底部文案-->
      <p class="login-text">{{login_text}}</p>
      <!-- E 底部文案-->
    </div>

    <!-- S 遮罩 -->
    <transition name="fade">
      <opacity-mask v-model="show_mask"></opacity-mask>
    </transition>
    <!-- E 遮罩 -->

    <transition name="slide-top">
      <!-- S 协议弹窗 -->
      <div class="alert-container protocol-alert" v-if="show_protocol">
        <div class="alert-content">
          <div class="protocol-container flex-center">
            <div class="protocol-box">
              <!-- <div class="top-title">
                <span class="dot"></span>
                <span class="txt">用户协议</span>
                <span class="dot"></span>
              </div>
              <h3 class="protocol-title">{{title}}</h3> -->
              <Agreement />
              <article class="protocol-content" v-html="contents"></article>
            </div>
          </div>
          <div class="btn close-btn" @click.stop="closeProtocol"></div>
        </div>
      </div>
      <!-- E 协议弹窗 -->
    </transition>

    <!-- S 提示弹窗 -->
    <alert-tip :type="type" :show-alert="show_tip" :alert-text="tip" @changeShowAlert="changeShow"></alert-tip>
    <!-- E 提示弹窗 -->
  </div>
</template>

<script>
import Validator from "@/utils/validator";
import AlertTip from "@/components/common/AlertTip";
import OpacityMask from "@/components/common/OpacityMask";
import {getImgCode, sendVerifyCode, getColor} from "@/services";
import {verifyMobile} from "@/services/my";
import {getTagArticleDetail} from "@/services/my";
import {toolTip, inputMixins} from "@/mixins";
import {debounce} from "@/utils/debounce";
import {mapState} from "vuex";
import Agreement from "./Agreement"

let base_code = "";
const COMPUTED_TIME = 60;
export default {
  name: "Register",
  components: {
    AlertTip,
    OpacityMask,
    Agreement
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo, //用户信息
      generalize_id: state => state.generalize_id, //推广人id
      business_id: state => state.business_id,
      is_show: state => state.is_show, //是否显示推广人信息
      nickname: state => state.nickname || "" //推广人
    })
  },
  mixins: [toolTip, inputMixins],
  data() {
    return {
      mobile: "", // 手机号码
      code: "", // 验证码
      img_code: "", // 图形验证码
      sms_code: "", // 短信验证码
      code_bool: true, // 控制显示获取验证码
      count: "", // 倒计时
      timer: null, // 计时器
      hasAgree: false, // 是否同意协议
      show_mask: false, // 是否显示遮罩
      show_protocol: false, // 是否显示协议
      title: '',
      contents: '',
      is_request: false,
      login_img: '',//登录图
      img_request: false,
      login_text: '',//登录文案
      name: '',//推广人员
      name_show: '0'//是否显示
    };
  },
  created() {
    this.loadImgCode();
    this.getTagArticleDetail()
    this.name = this.nickname
    this.name_show = this.is_show
    console.log(this.nickname,this.is_show,'22')
  },
  methods: {
    // 获取图形验证码
    async loadImgCode() {
      const res = await getImgCode();
      if (res.code === 0) {
        this.img_code = res.data.url;
        base_code = res.data.url;
      } else {
        if (process.env.NODE_ENV === "development") {
          this.show_layer("cancel", res.error_msg);
        }
      }
    },
    // 获取用户隐私协议
    async getTagArticleDetail() {
      const res = await getTagArticleDetail('user_hide');
      if (res.code === 0) {
        this.contents = res.data.contents;
        this.title = res.data.title;
      } else {
        if (process.env.NODE_ENV === "development") {
          this.show_layer("cancel", res.error_msg);
        }
      }
    },
    // 切换图形验证码
    getRandomImgCode: debounce(function () {
      this.img_code = base_code + "&" + Math.random();
    }, 300),
    // 同意协议？
    toggleAgree() {
      this.hasAgree = !this.hasAgree;
    },
    // 获取短信验证码
    getSmsCode: debounce(async function () {
      let mobile = this.mobile.replace(/\s+/g, ""),
        code = this.code.replace(/\s+/g, "");
      const {result, errorMessage} = Validator.validate("mobile", mobile);
      if (!mobile) {
        this.show_layer("cancel", "请输入手机号码");
        return;
      }
      if (!result) {
        this.show_layer("cancel", errorMessage);
        return;
      }
      if (!code) {
        this.show_layer("cancel", "请输入图形验证码");
        return;
      }
      if (this.timer) {
        clearInterval(this.timer);
      }

      const res = await sendVerifyCode(mobile, "register", code);
      if (res.code === 0) {
        this.show_layer("success", "验证码已发送，请注意查收");
        this.count = COMPUTED_TIME;
        this.code_bool = false;
        this.timer = setInterval(() => {
          if (this.count > 0) {
            this.count--;
          } else {
            this.code_bool = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
      else {
        this.show_layer("cancel", res.msg);
      }
    }, 300),
    //  显示协议
    openProtocolAlert() {
      this.show_mask = true;
      this.show_protocol = true;
    },
    // 关闭弹窗
    closeProtocol() {
      this.show_mask = false;
      this.show_protocol = false;
    },
    // 获取主题色
    async getThemeColor() {
      try {
        const res = await getColor(this.$store.state.userInfo.business_id);
        this.img_request = true
        if (res.code === 0) {
          const {login_img, login_text} = res.data;
          this.login_img = login_img
          this.login_text = login_text
          this.$forceUpdate();
        }
      } catch (error) {
        console.warn("Something bad happened in api getColor: ", error);
      }
    },
    // 提交表单
    submit: debounce(async function () {
      console.log(this.generalize_id,this.business_id,'kkkk');
      this.is_request = true;
      let mobile = this.mobile.replace(/\s+/g, ""),
        code = this.code.replace(/\s+/g, ""),
        sms_code = this.sms_code.replace(/\s+/g, "");
      const {result, errorMessage} = Validator.validate("mobile", mobile);
      if (!mobile) {
        this.show_layer("cancel", "请输入手机号码");
        this.is_request = false;
        return;
      }
      if (!result) {
        this.show_layer("cancel", errorMessage);
        this.is_request = false;
        return;
      }
      if (!code) {
        this.show_layer("cancel", "请输入图形验证码");
        this.is_request = false;
        return;
      }
      if (!sms_code) {
        this.show_layer("cancel", "请输入验证码");
        this.is_request = false;
        return;
      }
      if (!this.hasAgree) {
        this.show_layer("cancel", "请先同意协议");
        this.is_request = false;
        return;
      }
      try {
        const res = await verifyMobile(mobile, sms_code);
        this.is_request = false;
        if (res.code === 0) {
          this.$router.push({
            name: "register_set_password",
            query: {
              mobile: mobile
            }
          });
        } else {
		  this.show_layer("cancel", res.msg);
		}
      } catch (e) {
        console.log(e);
      }
    }, 300),
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  mounted() {
    if (JSON.stringify(this.userInfo) !== '{}') {
      this.$router.replace("home");
    }
    this.getThemeColor()
  }
};
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";
 .nickname {
    padding: 0.2rem 0 0 0.6rem;
  }
.page {
  .bj(#fff);
  padding-bottom: 0.6rem;

  .img-bg {
    .wh(100%, 3.64rem);
  }
  .tab-list {
    position: relative;
    .wh(100%, 1rem);
    margin-bottom: 0.4rem;
    overflow: hidden;

    li {
      .wh(50%, 100%);
      float: left;
      position: relative;
      overflow: hidden;
      z-index: 0;

      .btn {
        position: relative;
        .wh(100%, 100%);
        .sc(@fontsize-medium, @color-grey);
        line-height: 0.4rem;
        text-align: center;
      }

      &.active {
        .btn {
          .sc(@fontsize-large-x, @color-dark-grey);
          line-height: 0.5rem;
          font-weight: bold;

          &:after {
            content: "";
            .cl;
            bottom: 0;
            display: block;
            .wh(2rem, 0.04rem);
            background-color: var(--main-color);
            border-radius: 0.03rem;
          }
        }
      }
    }
  }

  .title {
    padding-left: 0.52rem;
    margin-bottom: 0.18rem;
    .sc(@fontsize-large-xxx, @color-dark-grey);
    line-height: 0.66rem;
    font-weight: bold;
  }

  .form {
    width: 100%;
    padding-left: 0.56rem;
    padding-right: 0.54rem;

    .form-group {
      position: relative;
      display: flex;
      align-items: center;
      padding-top: 0.4rem;
      padding-bottom: 0.3rem;
      box-sizing: border-box;
      z-index: 1;

      .form-label {
        position: absolute;
        top: 0.39rem;
        left: 0;
        .sc(@fontsize-medium, @color-dark-grey);
        line-height: 0.4rem;
        z-index: 4;

        .icon {
          display: inline-block;
          .wh(0.32rem, 0.32rem);
        }

        .icon-mobile {
          width: 0.24rem;
          .bis("../../assets/icon_denglu_shouji");
        }

        .icon-img-code {
          height: 0.28rem;
          .bis("../../assets/icon_denglu_yanzhengma");
        }

        .icon-sms-code {
          .bis("../../assets/icon_denglu_shuruyanzhengma");
        }
      }

      .form-control {
        position: relative;
        .wh(100%, 98%);
        padding-left: 0.48rem;
        border: none;
        box-sizing: border-box;
        .sc(@fontsize-medium, @color-dark-grey);
        line-height: 0.4rem;
        transition: 0.3s;
        z-index: 3;

        &::-webkit-input-placeholder {
          color: #b3b3b3;
        }
      }

      .img-box {
        .ct;
        right: 0;
        .wh(2.4rem, 0.8rem);
        z-index: 4;

        .img-code {
          .wh(100%, 100%);
        }
      }

      .send-btn,
      .countdown {
        .ct;
        right: 0;
        .wh(1.68rem, 0.52rem);
        .sc(@fontsize-small, #fff);
        line-height: 0.52rem;
        text-align: center;
        white-space: nowrap;
        border-radius: 0.26rem;
        z-index: 4;
      }

      .send-btn {
        background: var(--main-color);
        .touch-active;
      }

      .countdown {
        background: @color-light-grey;
      }
    }
  }

  .form-bottom {
    position: relative;
    display: flex;
    height: 0.34rem;
    padding-left: 0.56rem;
    padding-right: 0.54rem;
    margin-top: 0.2rem;
    /*margin-bottom: 0.58rem;*/

    .protocol-wrap {
      display: flex;
      align-items: center;

      .icon-wrap {
        .wh(0.28rem, 0.28rem);
        margin-right: 0.1rem;
      }

      .select-item {
        .wh(0.28rem, 0.28rem);
        color: @color-light-grey;
      }

      .selected-item {
        .wh(0.28rem, 0.28rem);
        color: var(--main-color);
      }

      .gray-txt {
        margin-right: 0.1rem;
        .sc(@fontsize-small, @color-dark-grey);
        line-height: 100%;
      }

      .protocol {
        .sc(@fontsize-medium, var(--main-color));
        line-height: 0.4rem;
      }
    }
  }

  .submit-btn {
    .wh(6.4rem, 0.88rem);
    margin: 0 auto;
    .sc(@fontsize-large, #fff);
    line-height: 0.88rem;
    text-align: center;
    white-space: nowrap;
    background-color: var(--main-color);
    border-radius: 0.5rem;
    overflow: hidden;
    .touch-active(var(--main-color));
  }

  .alert-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000000;
    backface-visibility: hidden;

    .alert-content {
      .cl;
    }
  }

  .protocol-alert {
    .alert-content {
      top: 0.7rem;

      .protocol-container {
        .wh(6.7rem, 8.86rem);
        margin-bottom: 0.4rem;
        background-color: #fff;
        border-radius: 0.08rem;
        border: 0.04rem solid var(--main-color);
        overflow: hidden;

        .protocol-box {
          display: flex;
          flex-direction: column;
          align-items: center;
          .wh(6.5rem, 8.66rem);
          padding: 0.3rem 0.2rem;
          background-color: #fff;
          border-radius: 0.08rem;
          border: 0.04rem solid var(--main-color);
          // overflow: hidden;

          .top-title {
            display: flex;
            align-items: center;
            margin-bottom: 0.76rem;

            .dot {
              display: inline-block;
              .wh(0.08rem, 0.08rem);
              background-color: @color-light-grey;
              border-radius: 50%;
            }

            .txt {
              margin: 0 0.2rem;
              .sc(@fontsize-medium, @color-light-grey);
              line-height: 0.44rem;
              letter-spacing: -0.02rem;
            }
          }

          .protocol-title {
            margin-bottom: 0.6rem;
            .sc(@fontsize-large-x, var(--main-color));
            line-height: 0.5rem;
            letter-spacing: 0.01rem;
            font-weight: bold;
          }

          .protocol-content {
            max-height: 60%;
            .sc(0.26rem, @color-grey);
            line-height: 0.44rem;
            word-wrap: break-word;
            word-break: break-all;
            overflow: hidden;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
          }
        }
      }

      .close-btn {
        .wh(0.68rem, 0.68rem);
        margin: 0 auto;
        .bis("../../assets/icon_denglu_guanbi");
      }
    }
  }

  .login-text {
    text-align: center;
    width: 100%;
    margin-top: 0.3rem;
  }
}
</style>
