var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "agreement" }, [
    _c("div", { staticClass: "agreement-con" }, [
      _vm._m(0),
      _c("h3", { staticClass: "protocol-title" }, [
        _vm._v(_vm._s(_vm.shopName) + "用户服务协议"),
      ]),
      _c("title", [_vm._v(_vm._s(_vm.shopName) + "用户服务协议")]),
      _c("p", [
        _vm._v(
          "欢迎您与" +
            _vm._s(_vm.shopName) +
            "共同签署《" +
            _vm._s(_vm.shopName) +
            "用户服务协议》（下称“本协议”）并使用" +
            _vm._s(_vm.shopName) +
            "服务！"
        ),
      ]),
      _c("p", [
        _vm._v(
          "\n            您在申请注册流程中点击同意本协议之前，应当认真阅读本协议。如您对本协议有任何疑问，可向" +
            _vm._s(_vm.shopName) +
            "客服咨询。\n        "
        ),
      ]),
      _c("p", [
        _vm._v(
          "\n            本协议是您与" +
            _vm._s(_vm.shopName) +
            "所有者之间就" +
            _vm._s(_vm.shopName) +
            '服务等相关事宜所订立的契约，请您仔细阅读本注册协议，您点击"同意并继续"按钮后，本协议即构成对双方有约束力的法律文件。\n        '
        ),
      ]),
      _c("p", [_vm._v("\n            第1条本服务条款的确认和接纳\n        ")]),
      _c("p", [
        _vm._v(
          "\n            1.1、服务的所有权和运作权归" +
            _vm._s(_vm.shopName) +
            "所有。用户同意所有注册协议条款并完成注册程序，才能成为本商城的正式用户。用户确认：本协议条款是处理双方权利义务的契约，始终有效，法律另有强制性规定或双方另有特别约定的，依其规定。\n        "
        ),
      ]),
      _c("p", [
        _vm._v(
          "\n            1.2、用户点击同意本协议的，即视为用户确认自己具有享受本商城服务、下单购物等相应的权利能力和行为能力，能够独立承担法律责任。\n        "
        ),
      ]),
      _c("p", [
        _vm._v(
          "\n            1.3、如果您在18周岁以下，您只能在父母或监护人的监护参与下才能使用本商城。\n        "
        ),
      ]),
      _c("p", [
        _vm._v(
          "\n            1.4、" +
            _vm._s(_vm.shopName) +
            "保留在中华人民共和国大陆地区现行法之法律允许的范围内依法决定拒绝服务、关闭用户账户、清除或编辑内容或取消订单的权利。\n        "
        ),
      ]),
      _c("p", [_vm._v("\n            第2条注册信息管理\n        ")]),
      _c("p", [
        _vm._v(
          "\n            2.1、用户应向本商城提供注册资料，用户确保其提供的注册资料真实、准确、完整、合法有效，用户注册资料如有变动的，应及时更新其注册资料。如果用户提供的注册资料不合法、不真实、不准确、不详尽的，用户需承担因此引起的相应责任及后果，并且" +
            _vm._s(_vm.shopName) +
            "保留终止用户使用" +
            _vm._s(_vm.shopName) +
            "各项服务的权利。\n        "
        ),
      ]),
      _c("p", [
        _vm._v(
          "\n            2.2、用户在本商城进行浏览、下单购物等活动时，涉及用户真实姓名/名称、通信地址、联系电话、电子邮箱等隐私信息的，本商城将予以严格保密，除非得到用户的授权或法律另有规定，本商城不会向外界披露用户隐私信息。\n        "
        ),
      ]),
      _c("p", [
        _vm._v(
          "\n            2.3、用户注册成功后，将产生用户名和密码等账户信息，您可以根据本商城规定改变您的密码。用户应谨慎合理的保存、使用其用户名和密码。账户因您主动泄露或遭受他人攻击、诈骗等行为导致的损失及后果，均由您自行承担。\n        "
        ),
      ]),
      _c("p", [
        _vm._v(
          "\n            2.4、用户同意，" +
            _vm._s(_vm.shopName) +
            "拥有通过邮件、短信电话等形式，向在本商城注册、购物用户、收货人发送订单信息、促销活动等告知信息的权利。如果用户认为被打扰，有选择拒绝接受相关信息的权利。\n        "
        ),
      ]),
      _c("p", [
        _vm._v(
          "\n            2.5、用户不得将在本商城注册获得的账户借给他人使用，否则用户应承担由此产生的全部责任，并与实际使用人承担连带责任。\n        "
        ),
      ]),
      _c("p", [
        _vm._v(
          "\n            2.6、用户同意，" +
            _vm._s(_vm.shopName) +
            "有权使用用户的注册信息、用户名、密码等信息，登录进入用户的注册账户，进行证据保全，包括但不限于公证、见证等。\n        "
        ),
      ]),
      _c("p", [
        _vm._v(
          "\n            2.7、除" +
            _vm._s(_vm.shopName) +
            "存在过错外，您应对您账户项下的所有行为结果（包括但不限于在线签署各类协议、发布信息、购买商品和服务及披露信息等）负责。\n        "
        ),
      ]),
      _c("p", [
        _vm._v(
          "\n            2.8、如发现任何未经授权使用您账户登录" +
            _vm._s(_vm.shopName) +
            "或其他可能导致您账户遭窃、遗失的情况，建议您立即通知" +
            _vm._s(_vm.shopName) +
            "。您理解" +
            _vm._s(_vm.shopName) +
            "对您的任何请求采取行动均需要合理时间，除" +
            _vm._s(_vm.shopName) +
            "存在过错外，" +
            _vm._s(_vm.shopName) +
            "对在采取行动前已经产生的后果不承担任何责任。\n        "
        ),
      ]),
      _c("p", [
        _vm._v(
          "\n            2.9、每个手机号码、每个收货地址仅对应一个用户账户。包括但不限于以下行为，视为虚假注册：\n        "
        ),
      ]),
      _c("p", [_vm._v("\n            以同一手机号码注册多个账户；\n        ")]),
      _c("p", [
        _vm._v(
          "\n            " +
            _vm._s(_vm.shopName) +
            "有权将上述多账户认定为一个用户账户。\n        "
        ),
      ]),
      _c("p", [
        _vm._v(
          "\n            " +
            _vm._s(_vm.shopName) +
            "有权取消虚假注册账户在商城上消费的权利。\n        "
        ),
      ]),
      _c("p", [_vm._v("\n            第3条商品信息\n        ")]),
      _c("p", [
        _vm._v(
          "\n            本商城上的商品价格、数量、是否有货等商品信息随时都有可能发生变动，本商城不作特别通知。由于本商城上商品信息的数量极其庞大，虽然本商城尽最大努力保证您所浏览商品信息的准确性，但因互联网技术因素等客观原因的问题，本商城显示的信息可能会有一定的滞后性或差错，对可能产生的此情形您知悉并理解；" +
            _vm._s(_vm.shopName) +
            '欢迎纠错。为表述便利，商品和服务简称为"商品"或"货物"。\n        '
        ),
      ]),
      _c("p", [_vm._v("\n            第4条订单\n        ")]),
      _c("p", [
        _vm._v(
          "\n            4.1、在您下订单时，请您仔细确认所购商品的名称、价格、数量、型号、规格、尺寸、联系地址、电话、收货人等信息。收货人与用户本人不一致的，收货人的行为和意思表示视为用户的行为和意思表示，用户应对收货人的行为及意思表示的法律后果承担连带责任。\n        "
        ),
      ]),
      _c("p", [
        _vm._v(
          "\n            4.2、您通过本商城订购商品，本商城上展示的商品和价格等信息仅仅是要约邀请，您的订单将成为订购商品的要约。收到您的订单后，我们将向您发送信息确认我们已收到您的订单，其中会注明订单的细节，但该确认不代表我们接受您的订单。只有当我们向您发出发货确认的信息，才构成我们对您的订单的接受，我们和您之间的订购合同才成立。如果您在一份订单里订购了多种商品，而我们只向您发出了其中部分商品的发货确认信息，那么我们和您之间的订购合同仅就该部分商品成立。您可以随时登陆您在本站注册的账户，查询您的订单状态\n        "
        ),
      ]),
      _c("p", [
        _vm._v(
          "\n            4.3、由于市场变化及各种以合理商业努力难以控制的因素的影响，本商城无法保证您提交的订单信息中希望购买的商品都会有货；在接收到您的订单后，如果发现所订购的商品全部或部分缺货，商城会就缺货的商品详细情况与您联系协商，并提供您选择的机会来取消整个订单或用等价商品来替换缺货的商品，经您同意处理订单信息。\n        "
        ),
      ]),
      _c("p", [
        _vm._v(
          "\n            4.4、您充分理解并完全同意：" +
            _vm._s(_vm.shopName) +
            "是一家面向消费者的购物网站，" +
            _vm._s(_vm.shopName) +
            "根据您账号下的商品购买记录及其他相关信息，如果发现您通过商城或者商城提供的其他网购渠道购买的商品并非用于个人消费或者正当使用用途的，对于您的这个用户账号的商品购买需求，" +
            _vm._s(_vm.shopName) +
            "有权不予接受；您如果已经下达订单，" +
            _vm._s(_vm.shopName) +
            "有权不予接受订单；" +
            _vm._s(_vm.shopName) +
            "如果已经接受订单的，有权单方面解除。同时，" +
            _vm._s(_vm.shopName) +
            "视情况还有权冻结您的这个用户账号，使之无法通过本商城以及商城提供的其他网购渠道下达订单、购买商品。\n        "
        ),
      ]),
      _c("p", [_vm._v("\n            第5条用户依法言行义务\n        ")]),
      _c("p", [
        _vm._v(
          "\n            本协议依据国家相关法律、法规、规章制定，用户同意严格遵守以下义务：\n        "
        ),
      ]),
      _c("p", [
        _vm._v(
          "\n            （1）不得传输或发表：煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论；\n        "
        ),
      ]),
      _c("p", [
        _vm._v(
          "\n            （2）从中国大陆向境外传输资料信息时必须符合中国有关法规；\n        "
        ),
      ]),
      _c("p", [
        _vm._v(
          "\n            （3）不得利用本商城从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动；\n        "
        ),
      ]),
      _c("p", [
        _vm._v(
          "\n            （4）不得干扰本商城的正常运转，不得侵入本商城及国家计算机信息系统；\n        "
        ),
      ]),
      _c("p", [
        _vm._v(
          "\n            （5）不得传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽的、不文明的等信息资料或言论；\n        "
        ),
      ]),
      _c("p", [
        _vm._v(
          "\n            （6）不得传输或发表损害国家社会公共利益和涉及国家安全的信息资料或言论；\n        "
        ),
      ]),
      _c("p", [
        _vm._v(
          "\n            （7）不得教唆他人从事本条所禁止的行为；\n        "
        ),
      ]),
      _c("p", [
        _vm._v(
          "\n            （8）不得利用在本商城注册的账户进行牟利性经营活动；\n        "
        ),
      ]),
      _c("p", [
        _vm._v(
          "\n            本网站保有删除站内各类不符合法律政策或不真实的信息内容而无须通知用户的权利。若用户未遵守以上规定的，本商城有权作出独立判断并采取暂停或关闭用户帐号等措施。用户须对自己在网上的言论和行为承担法律责任。\n        "
        ),
      ]),
      _c("p", [_vm._v("\n            第6条配送\n        ")]),
      _c("p", [
        _vm._v(
          "\n            6.1、销售方将会把商品（货物）送到您所指定的收货地址，所有在本商城上列出的送货时间为参考时间，参考时间的计算是根据库存状况、正常的处理过程和送货时间、送货地点的基础上估计得出的。\n        "
        ),
      ]),
      _c("p", [
        _vm._v(
          "\n            6.2、因如下情况造成订单延迟或无法配送等，销售方不承担延迟配送的责任：\n        "
        ),
      ]),
      _c("p", [
        _vm._v(
          "\n            （1）用户提供的信息错误、地址不详细等原因导致的；\n        "
        ),
      ]),
      _c("p", [
        _vm._v(
          "\n            （2）货物送达后无人签收，导致无法配送或延迟配送的；\n        "
        ),
      ]),
      _c("p", [_vm._v("\n            （3）情势变更因素导致的；\n        ")]),
      _c("p", [
        _vm._v(
          "\n            （4）不可抗力因素导致的，例如：自然灾害、交通戒严、突发战争等。\n        "
        ),
      ]),
      _c("p", [_vm._v("\n            第7条交易争议处理\n        ")]),
      _c("p", [
        _vm._v(
          "\n            您在" +
            _vm._s(_vm.shopName) +
            "交易过程中与其他用户发生争议的，您或其他用户中任何一方均有权选择以下途径解决：\n        "
        ),
      ]),
      _c("p", [
        _vm._v(
          "\n            （1）请" +
            _vm._s(_vm.shopName) +
            "协助调解；\n        "
        ),
      ]),
      _c("p", [
        _vm._v(
          "\n            （2）请求消费者协会或者其他依法成立的调解组织调解；\n        "
        ),
      ]),
      _c("p", [_vm._v("\n            （3）向有关行政部门投诉；\n        ")]),
      _c("p", [
        _vm._v(
          "\n            （4）根据与争议相对方达成的仲裁协议（如有）提请仲裁机构仲裁；\n        "
        ),
      ]),
      _c("p", [_vm._v("\n            （5）向人民法院提起诉讼。\n        ")]),
      _c("p", [
        _vm._v(
          "\n            当您通过" +
            _vm._s(_vm.shopName) +
            "协助调解时，如果对" +
            _vm._s(_vm.shopName) +
            "的调解结果不满，您仍有权采取其他争议处理途径解决争议。\n        "
        ),
      ]),
      _c("p", [_vm._v("\n            第8条协议更新及用户关注义务\n        ")]),
      _c("p", [
        _vm._v(
          "\n            根据国家法律法规变化及网站运营需要，" +
            _vm._s(_vm.shopName) +
            "有权对本协议条款不时地进行修改，修改后的协议一旦被张贴在本商城上即生效，并代替原来的协议。用户可随时登录查阅最新协议；用户有义务不时关注并阅读最新版的协议及网站公告。如用户不同意更新后的协议，可以且应立即停止接受" +
            _vm._s(_vm.shopName) +
            "依据本协议提供的服务；如用户继续使用本网站提供的服务的，即视为同意更新后的协议。" +
            _vm._s(_vm.shopName) +
            "建议您在使用本商城之前阅读本协议及本的公告。如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。\n        "
        ),
      ]),
      _c("p", [
        _vm._v(
          "\n            本协议的订立、执行和解释及争议的解决均应适用在中华人民共和国大陆地区现行有效法律、法规（但不包括其冲突法规则）。如发生本协议与现行法律相抵触时，则这些条款将完全按法律规定重新解释，而其它有效条款继续有效。如缔约方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向有管辖权的中华人民共和国大陆地区法院提起诉讼。\n        "
        ),
      ]),
      _c("p", [
        _vm._v(
          '\n            您点击本协议下方的"同意并继续"按钮即视为您完全接受本协议，在点击之前请您再次确认已知悉并完全理解本协议的全部内容。\n        '
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "top-title" }, [
      _c("span", { staticClass: "dot" }),
      _c("span", { staticClass: "txt" }, [_vm._v("用户协议")]),
      _c("span", { staticClass: "dot" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }