<template>
	<div class="page">
		<HeadNav/>
		<!-- S 表单区 -->
		<div class="form">
			<div class="form-group border-bottom-1px" v-if="oldStatus">
				<label for="old_password" class="form-label"><i class="icon icon-password"></i></label>
				<input type="password" required v-model="old_password" id="old_password" class="form-control" placeholder="请输入你的原账户密码" />
			</div>
			<div class="form-group border-bottom-1px">
				<label for="new_password" class="form-label"><i class="icon icon-password"></i></label>
				<input type="password" required v-model="new_password" id="new_password" class="form-control" placeholder="请设置你的账户密码" />
			</div>
			<div class="form-group border-bottom-1px">
				<label for="confirm_password" class="form-label"><i class="icon icon-password"></i></label>
				<input type="password" required v-model="confirm_password" id="confirm_password" class="form-control" placeholder="请再次输入账户密码" />
			</div>
		</div>
		<!-- E 表单区 -->

		<!-- S tip -->
		<div class="form-bottom"><p class="tip">注：账户密码在后台网站登录会用到哦</p></div>
		<!-- E tip -->

		<!-- S 提交按钮 -->
		<div class="btn submit-btn flex-center" @click.prevent="doSubmit">完成</div>
		<!-- E 提交按钮 -->

		<!-- S 提示弹窗 -->
		<alert-tip :type="type" :show-alert="show_tip" :alert-text="tip" @changeShowAlert="changeShow"></alert-tip>
		<!-- E 提示弹窗 -->
	</div>
</template>

<script>
import Validator from '@/utils/validator';
import AlertTip from '@/components/common/AlertTip';
import HeadNav from "@/components/common/HeadNav";
import { forgot } from '@/services';
import { changePassword } from '@/services/user';
import { toolTip } from '@/mixins';
import { debounce } from '@/utils/debounce';
import md5 from 'js-md5';
import { getStore } from "@/utils/common";

export default {
	name: 'set_password',
	components: {
		AlertTip,
		HeadNav
	},
	mixins: [toolTip],
	beforeRouteEnter (to, from, next) {
		if (from.name === 'setting') {
			next();
		} else {
			history.go(-1);
		}
	},
	data() {
		return {
			oldStatus: false, //是否为修改密码状态
			mobile: '', // 手机号码
			sms_code: '', // 短信验证码
			old_password: '', //原密码
			new_password: '', // 新密码
			confirm_password: '' // 再次输入密码
		};
	},
	created() {
		this.getParams();
	},
	methods: {
		getParams() {
			this.mobile = this.$route.query.mobile ? this.$route.query.mobile : '';
			if (!this.mobile)
				//this.$router.go(-1);
				this.oldStatus = true;
		},

		// 点击提交
		doSubmit: debounce(function() {
			let mobile = this.mobile,
				new_password = this.new_password.replace(/\s+/g, ''),
				confirm_password = this.confirm_password.replace(/\s+/g, '');

			//修改密码状态判断密码旧密码是否为空
			let old_password = '';
			if (this.oldStatus) {
				old_password = this.old_password.replace(/\s+/g, '');
				if (!old_password) {
					this.show_layer('cancel', '请输入原密码');
					return;
				}
			}
			if (!new_password) {
				this.show_layer('cancel', '请输入新密码');
				return;
			}
			if (!confirm_password) {
				this.show_layer('cancel', '请输入确认密码');
				return;
			}
			if (new_password != confirm_password) {
				this.show_layer('cancel', '两次输入不一致');
				return;
			}
			if (this.oldStatus) {
				this.changePwd(old_password, confirm_password);
			} else {
				this.forgetPwd(new_password, confirm_password, mobile);
			}
		}, 300),

		//忘记密码
		async forgetPwd(newPwd, confirmPwd, mobile) {
			try {
				const res = await forgot(this.$route.query.code, md5(newPwd), md5(confirmPwd), mobile);
				if (res.code === 0) {
					this.show_layer('success', '密码设置成功');
					setTimeout(() => {
						this.$router.replace('login');
					}, 1000);
				} else {
					this.show_layer('cancel', res.msg);
				}
			} catch (error) {
				console.warn('Something bad happened: ', error);
			}
		},

		//修改密码
		async changePwd(oldPwd, confirmPwd) {
			try {
				let comId = 1975;
				if(JSON.parse(getStore('businessInfo')).id != '2052'){
					comId = 2765
				}else{
					comId = 1975;
				}
				console.log(comId,'comId')
				const res = await changePassword(md5(oldPwd), md5(confirmPwd),comId);
				if (res.code === 0) {
					this.show_layer('success', '密码修改成功');
					this.$router.go(-1);
				} else {
					this.show_layer('cancel', res.msg);
				}
			} catch (error) {
				console.warn('Something bad happened: ', error);
			}
		}
	}
};
</script>

<style lang="less" scoped>
@import '../../common/less/variable.less';
@import '../../common/less/mixin.less';

.page {
	.bj(#fff);
}

.form {
	width: 100%;
	padding-left: 0.56rem;
	padding-right: 0.54rem;

	.form-group {
		position: relative;
		display: flex;
		align-items: center;
		padding-top: 0.4rem;
		padding-bottom: 0.3rem;
		box-sizing: border-box;
		z-index: 1;

		.form-label {
			position: absolute;
			top: 0.44rem;
			left: 0;
			.sc(@fontsize-medium, @color-dark-grey);
			line-height: 0.4rem;
			z-index: 4;

			.icon {
				display: inline-block;
				.wh(0.32rem, 0.32rem);
			}

			.icon-password {
				width: 0.28rem;
				.bis('../../assets/icon_denglu_mima');
			}
		}

		.form-control {
			position: relative;
			.wh(100%, 98%);
			padding-left: 0.48rem;
			border: none;
			box-sizing: border-box;
			.sc(@fontsize-medium, @color-dark-grey);
			line-height: 0.4rem;
			transition: 0.3s;
			z-index: 3;

			&::-webkit-input-placeholder {
				color: #b3b3b3;
			}
		}
	}
}

.form-bottom {
	position: relative;
	display: flex;
	height: 0.34rem;
	padding-left: 0.56rem;
	padding-right: 0.54rem;
	margin-top: 0.2rem;
	margin-bottom: 1.7rem;

	.tip {
		.sc(@fontsize-small, @color-light-grey);
		line-height: 0.34rem;
		white-space: nowrap;
	}
}

.submit-btn {
	.wh(6.4rem, 0.88rem);
	margin: 0 auto;
	.sc(@fontsize-large, #fff);
	line-height: 0.88rem;
	text-align: center;
	white-space: nowrap;
	background-color: var(--main-color);
	border-radius: 0.5rem;
	overflow: hidden;
	.touch-active(var(--main-color));
}
</style>
