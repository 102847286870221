<template>
  <div>
    <div v-if="loginSure" class="txt">
      正在跳转商城
    </div>
    <div v-if="bingStatus">
      <div class="page">
        <!-- S 背景图 -->
        <div class="img-bg"></div>
        <!-- E 背景图 -->

        <h3 class="title">Hi，请先绑定{{accountTxt}}</h3>

        <!-- S 表单 -->
        <div class="form">
          <div v-if="!pointsTopic" class="form-group border-bottom-1px">
            <label for="mobile" class="form-label">
              <i class="icon icon-mobile"></i>
            </label>
            <input
              type="text"
              pattern="\d*"
              required
              v-model="mobile"
              id="mobile"
              class="form-control"
              maxlength="11"
              placeholder="请输入手机号码"
            />
          </div>
          <div v-else class="form-group border-bottom-1px">
            <label class="form-label">
              <i class="icon icon-mobile"></i>
            </label>
            <input
              type="text"
              v-model="kehuNumber"
              class="form-control"
              placeholder="请输入客户号"
            />
          </div>
          <div class="cell" v-if="pointsTopic">
            <i class="icon icon-password"></i>
            <input type="password" v-model="new_password" placeholder="请输入账户密码">
          </div>
          <div v-else class="form-group border-bottom-1px">
            <label for="code" class="form-label">
              <i class="icon icon-sms-code"></i>
            </label>
            <input
              type="text"
              required
              v-model="sms_code"
              id="code"
              class="form-control"
              maxlength="6"
              placeholder="请输入短信验证码"
            />
            <div
              class="btn send-btn flex-center"
              v-show="code_bool"
              @click.prevent="getSmsCode"
            >
              发送验证码
            </div>
            <div class="countdown flex-center" v-show="!code_bool">
              {{ count }}s
            </div>
          </div>
          <div v-if="pwdStatus">
            <div class="form-group border-bottom-1px">
              <label for="new_password" class="form-label">
                <i class="icon icon-password"></i>
              </label>
              <input
                type="password"
                required
                v-model="new_password"
                id="new_password"
                class="form-control"
                placeholder="请设置你的登录密码"
              />
            </div>
            <div class="form-group border-bottom-1px">
              <label for="confirm_password" class="form-label">
                <i class="icon icon-password"></i>
              </label>
              <input
                type="password"
                required
                v-model="confirm_password"
                id="confirm_password"
                class="form-control"
                placeholder="请再次输入登录密码"
              />
            </div>
            <div
              class="form-group border-bottom-1px"
              v-if="nameCode && pwdStatus"
            >
              <label for="new_password" class="form-label">
                <yd-icon name="ucenter" color="#333" size="0.4rem"></yd-icon>
              </label>
              <input
                type="text"
                required
                v-model="nickName"
                id="nike_name"
                class="form-control"
                placeholder="请输入你的姓名"
              />
            </div>
          </div>
        </div>
        <!-- E 表单 -->

        <div class="btn submit-btn flex-center" @click="pointsTopic ? topicSubmit() : submit()">确定</div>
      </div>
    </div>

    <!-- S 提示弹窗 -->
    <alert-tip
      :type="type"
      :show-alert="show_tip"
      :alert-text="tip"
      @changeShowAlert="changeShow"
    ></alert-tip>
    <!-- E 提示弹窗 -->
  </div>
</template>

<script>
import Validator from "@/utils/validator";
import AlertTip from "@/components/common/AlertTip";
import OpacityMask from "@/components/common/OpacityMask";
import { sendSms, bindLogin, weChatLogin, bindingWxHaiYuanSu } from "@/services/user";
import { toolTip, inputMixins } from "@/mixins";
import { debounce } from "@/utils/debounce";
import md5 from "js-md5";
import { mapMutations, mapState } from "vuex";
import { customAccount } from "@/utils/custom";

let base_code = "";
const COMPUTED_TIME = 60;
export default {
  name: "Register",
  components: {
    AlertTip,
    OpacityMask
  },
  mixins: [toolTip, inputMixins],
  data() {
    return {
      loginSure: false, //已绑定过手机
      bingStatus: false, //未绑定手机
      pwdStatus: false, //是否需要设置密码
      mobile: "", // 手机号码
      sms_code: "", // 短信验证码
      code_bool: true, // 控制显示获取验证码
      count: "", // 倒计时
      timer: null, // 计时器
      contents: "",
      new_password: "", // 新密码
      confirm_password: "", // 再次输入密码
      nickName: "", //用户昵称
      kehuNumber: ''  //  客户号
    };
  },
  created() {
    document.title = this.$route.meta.title;
    this.getParams(this.GetUrlParam("code"));
    this.isPointTopic()
    //this.getParams(2);
  },
  computed: {
    ...mapState({
      nameCode: state => state.config.register_require_nickname //是否需要填写昵称
    })
  },
  methods: {
    ...mapMutations(["getUser", "getSessionId"]),

    //获取连接问号参
    GetUrlParam(paraName) {
      var url = window.document.location.href;
      var arrObj = url.split("?");
      if (arrObj.length > 1) {
        var arrPara = arrObj[1].split("&");
        var arr;
        for (var i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split("=");
          if (arr != null && arr[0] == paraName) {
            return arr[1].split("#")[0];
          }
        }
        return "";
      } else {
        return "";
      }
    },

    async getParams(code) {
      try {
        const res = await weChatLogin(code);
        let status = res.code;
        if (status === 0) {
          this.loginSure = true;
          const { PHPSESSID, user } = res.data;
          SID = PHPSESSID;
          this.getSessionId(PHPSESSID);
          this.$store.dispatch("getUserInfo");
          await this.getUser(user);
          await this.getThemeColor();
          // try {
          const data = await this.$store.dispatch("getBusinessRate");
          if (JSON.stringify(data) !== "{}") {
            // this.$router.replace("home");
            this.$router.push(sessionStorage.route || 'home')
          }
          // } catch (e) {

          // }
        } else if (status === 2) {
          this.bingStatus = true;
        } else if (status === 1001) {
           this.$dialog.toast({
            mes: res.msg
          });
          setTimeout(() => {
            this.$router.replace("home");
          }, 1000)
        } else {
           this.$dialog.toast({
            mes: res.msg
          });
        }
      } catch (e) {
        this.bingStatus = true;
      }
      /* if (code === 0) {
		  this.loginSure = true;
		  //this.$router.replace("home");
	  } else if (code === 2) {
		  this.bingStatus = true;
	  } */
    },

    // 获取短信验证码
    getSmsCode: debounce(async function() {
      let mobile = this.mobile.replace(/\s+/g, "");
      const { result, errorMessage } = Validator.validate("mobile", mobile);
      if (!mobile) {
        this.show_layer("cancel", "请输入手机号码");
        return;
      }
      if (!result) {
        this.show_layer("cancel", errorMessage);
        return;
      }
      if (this.timer) {
        clearInterval(this.timer);
      }

      const res = await sendSms(mobile);
      if (res.code === 0) {
        this.show_layer("success", "验证码已发送，请注意查收");
        this.count = COMPUTED_TIME;
        this.code_bool = false;
        if (res.data.status === 0) {
          this.pwdStatus = true;
        }
        this.timer = setInterval(() => {
          if (this.count > 0) {
            this.count--;
          } else {
            this.code_bool = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      } else {
        this.$dialog.toast({
          mes: res.msg
        });
      }
    }, 300),

    // 获取主题色
    async getThemeColor() {
      try {
        const res = await getColor(this.$store.state.userInfo.business_id);
        if (res.code === 0) {
          const { color } = res.data;
          document.body.style.setProperty("--main-color", color);
        }
      } catch (error) {
        console.warn("Something bad happened in api getColor: ", error);
      }
    },

    topicSubmit: debounce(async function() {
      if (!this.kehuNumber) {
        this.show_layer("cancel", "请输入客户号");
        return
      }
      let new_password = this.new_password.replace(/\s+/g, "")
      if (!new_password) {
        this.show_layer("cancel", "请输入密码");
        return;
      }
      try {
        const res = await bindingWxHaiYuanSu(
          md5(new_password),
          this.kehuNumber
        );
        if (res.code === 0) {
          this.show_layer("success", "设置成功");

          // sessionStorage.setItem('nickName', nickName);
          const { PHPSESSID, user } = res.data;
          SID = PHPSESSID;
          this.getSessionId(PHPSESSID);
          await this.getUser(user);
          await this.getThemeColor();
          this.$store.dispatch("getUserInfo");
          try {
            const data = await this.$store.dispatch("getBusinessRate");
            if (JSON.stringify(data) !== "{}") {
              //this.$router.replace("home");
              this.$router.replace(sessionStorage.route || 'home');
            }
          } catch (e) {}
        } else {
          this.show_layer("cancel", res.msg);
        }
        } catch (e) {
          console.warn("Something bad happened: ", e);
        }
      }, 300),

    // 提交表单
    submit: debounce(async function() {
      let mobile = this.mobile.replace(/\s+/g, ""),
        sms_code = this.sms_code.replace(/\s+/g, "");
      const { result, errorMessage } = Validator.validate("mobile", mobile);
      let new_password = this.new_password.replace(/\s+/g, ""),
        confirm_password = this.confirm_password.replace(/\s+/g, "");
      if (this.nameCode && this.pwdStatus) {
        if (!this.nickName) {
          this.show_layer("cancel", "请输入你的姓名");
          this.is_request = false;
          return;
        }
      }
      if (!mobile) {
        this.show_layer("cancel", "请输入手机号码");
        return;
      }
      if (!result) {
        this.show_layer("cancel", errorMessage);
        return;
      }
      if (!sms_code) {
        this.show_layer("cancel", "请输入验证码");
        return;
      }
      //需要设置情况下的判断
      if (this.pwdStatus) {
        if (!new_password) {
          this.show_layer("cancel", "请输入密码");
          return;
        }
        if (!confirm_password) {
          this.show_layer("cancel", "请输入确认密码");
          return;
        }
        if (new_password != confirm_password) {
          this.show_layer("cancel", "两次输入不一致");
          return;
        }
      }
      try {
        const res = await bindLogin(
          mobile,
          sms_code,
          md5(confirm_password),
          this.nickName
        );
        if (res.code === 0) {
          this.show_layer("success", "设置成功");

          // sessionStorage.setItem('nickName', nickName);
          const { PHPSESSID, user } = res.data;
          SID = PHPSESSID;
          this.getSessionId(PHPSESSID);
          await this.getUser(user);
          await this.getThemeColor();
          this.$store.dispatch("getUserInfo");
          try {
            const data = await this.$store.dispatch("getBusinessRate");
            if (JSON.stringify(data) !== "{}") {
              //this.$router.replace("home");
              this.$router.replace(sessionStorage.route || 'home');
            }
          } catch (e) {}
        } else {
          //this.show_layer("cancel", "操作失败请重试");
        }
      } catch (e) {
        console.warn("Something bad happened: ", e);
      }
    }, 300),
   //  积分专题定制
    isPointTopic() {
      if ( customAccount('VUE_APP_POINTS_TOPIC') ) {
        this.pointsTopic = true
        this.accountTxt = '客户号'
        document.title = '绑定客户号'
        console.log('12', this.pointsTopic)
      }
    } 
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
};
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.txt {
  text-align: center;
  font-size: 0.35rem;
  margin-top: 1rem;
}
.page {
  .bj(#fff);

  .img-bg {
    .wh(100%, 3.64rem);
    .bis("../../assets/img_denglu_bg");
  }

  .tab-list {
    position: relative;
    .wh(100%, 1rem);
    margin-bottom: 0.4rem;
    overflow: hidden;

    li {
      .wh(50%, 100%);
      float: left;
      position: relative;
      overflow: hidden;
      z-index: 0;

      .btn {
        position: relative;
        .wh(100%, 100%);
        .sc(@fontsize-medium, @color-grey);
        line-height: 0.4rem;
        text-align: center;
      }

      &.active {
        .btn {
          .sc(@fontsize-large-x, @color-dark-grey);
          line-height: 0.5rem;
          font-weight: bold;

          &:after {
            contents: "";
            .cl;
            bottom: 0;
            display: block;
            .wh(2rem, 0.04rem);
            background-color: var(--main-color);
            border-radius: 0.03rem;
          }
        }
      }
    }
  }

  .title {
    padding-left: 0.52rem;
    margin-bottom: 0.18rem;
    .sc(@fontsize-large-xxx, @color-dark-grey);
    line-height: 0.66rem;
    font-weight: bold;
    margin-top: 0.5rem;
  }

  .form {
    width: 100%;
    padding-left: 0.56rem;
    padding-right: 0.54rem;

    .form-group {
      position: relative;
      display: flex;
      align-items: center;
      padding-top: 0.4rem;
      padding-bottom: 0.3rem;
      box-sizing: border-box;
      z-index: 1;

      .form-label {
        position: absolute;
        top: 0.44rem;
        left: 0;
        .sc(@fontsize-medium, @color-dark-grey);
        line-height: 0.4rem;
        z-index: 4;

        .icon {
          display: inline-block;
          .wh(0.32rem, 0.32rem);
        }

        .icon-mobile {
          width: 0.24rem;
          .bis("../../assets/icon_denglu_shouji");
        }

        .icon-img-code {
          height: 0.28rem;
          .bis("../../assets/icon_denglu_yanzhengma");
        }

        .icon-sms-code {
          .bis("../../assets/icon_denglu_shuruyanzhengma");
        }

        .icon-password {
          width: 0.28rem;
          .bis("../../assets/icon_denglu_mima");
        }
      }

      .form-control {
        position: relative;
        .wh(100%, 98%);
        padding-left: 0.48rem;
        border: none;
        box-sizing: border-box;
        .sc(@fontsize-medium, @color-dark-grey);
        line-height: 0.4rem;
        transition: 0.3s;
        z-index: 3;

        &::-webkit-input-placeholder {
          color: #b3b3b3;
        }
      }

      .img-box {
        .ct;
        right: 0;
        .wh(1.2rem, 0.6rem);
        z-index: 4;

        .img-code {
          .wh(100%, 100%);
        }
      }

      .send-btn,
      .countdown {
        .ct;
        right: 0;
        .wh(1.68rem, 0.52rem);
        .sc(@fontsize-small, #fff);
        line-height: 0.52rem;
        text-align: center;
        white-space: nowrap;
        border-radius: 0.26rem;
        z-index: 4;
      }

      .send-btn {
        background: var(--main-color);
        .touch-active;
      }

      .countdown {
        background: @color-light-grey;
      }
    }

    .cell {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      padding-left: 0.52rem;
      padding-top: 0.4rem;
      padding-bottom: 0.3rem;
      border-bottom: 1px solid @color-row-line;

      .icon {
        position: absolute;
        top: 0.44rem;
        left: 0;
        display: inline-block;
        .wh(0.32rem, 0.32rem);
        z-index: 2;
      }

      input {
        .wh(100%, 0.4rem);
        .sc(@fontsize-medium, @color-dark-grey);
        line-height: 0.4rem;

        &::-webkit-input-placeholder {
          color: #b3b3b3;
        }
      }

      .icon-mobile {
        width: 0.24rem;
        .bis("../../assets/icon_denglu_shouji");
      }

      .icon-password {
        width: 0.28rem;
        .bis("../../assets/icon_denglu_mima");
      }
	  
	  .icon-img-code {
      height: 0.28rem;
      .bis("../../assets/icon_denglu_yanzhengma");
      }
      
      .icon-sms-code {          
      .bis("../../assets/icon_denglu_shuruyanzhengma");        
      }
      
      .img-box {
        .ct;
        right: 0;
        .wh(2.4rem, 0.8rem);
        z-index: 4;
      
        .img-code {
          .wh(100%, 100%);
        }
      }
      
      .send-btn,      
      .countdown {        
        .ct;        
        right: 0;        
        .wh(1.68rem, 0.52rem);        
        .sc(@fontsize-small, #fff);        
        line-height: 0.52rem;        
        text-align: center;        
        white-space: nowrap;        
        border-radius: 0.26rem;        
        z-index: 4;      
      }
      
      .send-btn {
        background: var(--main-color);
        .touch-active;
      }
      
      .countdown {
        background: @color-light-grey;
      }
    }
  }

  .form-bottom {
    position: relative;
    display: flex;
    height: 0.34rem;
    padding-left: 0.56rem;
    padding-right: 0.54rem;
    margin-top: 0.2rem;
    margin-bottom: 0.58rem;

    .protocol-wrap {
      display: flex;
      align-items: center;

      .icon-wrap {
        .wh(0.28rem, 0.28rem);
        margin-right: 0.1rem;
      }

      .select-item {
        .wh(0.28rem, 0.28rem);
        color: @color-light-grey;
      }

      .selected-item {
        .wh(0.28rem, 0.28rem);
        color: var(--main-color);
      }

      .gray-txt {
        margin-right: 0.1rem;
        .sc(@fontsize-small, @color-dark-grey);
        line-height: 100%;
      }

      .protocol {
        .sc(@fontsize-medium, var(--main-color));
        line-height: 0.4rem;
      }
    }
  }

  .submit-btn {
    .wh(6.4rem, 0.88rem);
    margin: 0.8rem auto 0;
    .sc(@fontsize-large, #fff);
    line-height: 0.88rem;
    text-align: center;
    white-space: nowrap;
    background-color: var(--main-color);
    border-radius: 0.5rem;
    overflow: hidden;
    .touch-active(var(--main-color));
  }

  .alert-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000000;
    backface-visibility: hidden;

    .alert-content {
      .cl;
    }
  }

  .protocol-alert {
    .alert-content {
      top: 0.7rem;

      .protocol-container {
        .wh(6.7rem, 8.86rem);
        margin-bottom: 0.4rem;
        background-color: #fff;
        border-radius: 0.08rem;
        border: 0.04rem solid var(--main-color);
        overflow: hidden;

        .protocol-box {
          display: flex;
          flex-direction: column;
          align-items: center;
          .wh(6.5rem, 8.66rem);
          padding: 0.3rem 0.2rem;
          background-color: #fff;
          border-radius: 0.08rem;
          border: 0.04rem solid var(--main-color);
          overflow: hidden;

          .top-title {
            display: flex;
            align-items: center;
            margin-bottom: 0.76rem;

            .dot {
              display: inline-block;
              .wh(0.08rem, 0.08rem);
              background-color: @color-light-grey;
              border-radius: 50%;
            }

            .txt {
              margin: 0 0.2rem;
              .sc(@fontsize-medium, @color-light-grey);
              line-height: 0.44rem;
              letter-spacing: -0.02rem;
            }
          }

          .protocol-title {
            margin-bottom: 0.6rem;
            .sc(@fontsize-large-x, var(--main-color));
            line-height: 0.5rem;
            letter-spacing: 0.01rem;
            font-weight: bold;
          }

          .protocol-content {
            max-height: 60%;
            .sc(0.26rem, @color-grey);
            line-height: 0.44rem;
            word-wrap: break-word;
            word-break: break-all;
            overflow: hidden;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
          }
        }
      }

      .close-btn {
        .wh(0.68rem, 0.68rem);
        margin: 0 auto;
        .bis("../../assets/icon_denglu_guanbi");
      }
    }
  }
}
</style>
<style lang="less">
.personal_page {
  input {
    color: #333 !important;
  }
}
</style>
