<template>
  <div class="page">
    <!-- S 背景图 -->
    <div class="img-bg"></div>
    <!-- E 背景图 -->

    <!-- S tab -->
    <ul class="tab-list">
      <li :class="{ active: $route.name === 'login' }">
        <router-link to="login" tag="div" class="btn login-btn flex-center"
          >登录</router-link
        >
      </li>
      <li
        :class="{
          active:
            $route.name === 'register' ||
            $route.name === 'register_set_password'
        }"
      >
        <router-link
          to="register"
          tag="div"
          class="btn register-btn flex-center"
          >注册</router-link
        >
      </li>
    </ul>
    <!-- E tab -->

    <h3 class="title">请设置账户密码</h3>

    <!-- S 表单区 -->
    <div class="form">
      <div class="form-group border-bottom-1px" v-if="nameCode">
        <label for="new_password" class="form-label">
          <yd-icon name="ucenter" color="#999" size="0.4rem"></yd-icon>
        </label>
        <input
          type="text"
          required
          v-model="nickName"
          id="nike_name"
          class="form-control"
          placeholder="请输入你的姓名"
        />
      </div>
      <div class="form-group border-bottom-1px">
        <label for="new_password" class="form-label">
          <i class="icon icon-password"></i>
        </label>
        <input
          type="password"
          required
          v-model="new_password"
          id="new_password"
          class="form-control"
          placeholder="请设置你的账户密码"
        />
      </div>
      <div class="form-group border-bottom-1px">
        <label for="confirm_password" class="form-label">
          <i class="icon icon-password"></i>
        </label>
        <input
          type="password"
          required
          v-model="confirm_password"
          id="confirm_password"
          class="form-control"
          placeholder="请再次输入账户密码"
        />
      </div>
    </div>
    <!-- E 表单区 -->

    <!-- S tip -->
    <div class="form-bottom">
      <p class="tip">注：账户密码在后台网站登录会用到哦</p>
    </div>
    <!-- E tip -->

    <!-- S 提交按钮 -->
    <div
      class="btn submit-btn flex-center"
      v-if="!is_request"
      @click.prevent="doSubmit"
    >
      确定
    </div>
    <div class="btn submit-btn flex-center" v-else @click.prevent="doSubmit">
      确定
    </div>
    <!-- E 提交按钮 -->

    <!-- S 提示弹窗 -->
    <alert-tip
      :type="type"
      :show-alert="show_tip"
      :alert-text="tip"
      @changeShowAlert="changeShow"
    ></alert-tip>
    <!-- E 提示弹窗 -->
  </div>
</template>

<script>
import Validator from "@/utils/validator";
import AlertTip from "@/components/common/AlertTip";
import { register, register2, getColor } from "@/services";
import { toolTip } from "@/mixins";
import { debounce } from "@/utils/debounce";
import md5 from "js-md5";
import { mapMutations, mapState } from "vuex";

export default {
  name: "RegisterSetPassword",
  components: {
    AlertTip
  },
  mixins: [toolTip],
  data() {
    return {
      mobile: "", // 手机号码
      sms_code: "", // 短信验证码
      new_password: "", // 新密码
      confirm_password: "", // 再次输入密码
      is_request: false,
      nickName: "" //用户昵称
    };
  },
  created() {
    this.mobile = this.$route.query.mobile || "";
  },
  computed: {
    ...mapState({
      businessType: state => state.businessInfo.type, //1现金商城，2积分商品，3内采商城
      nameCode: state => state.config.register_require_nickname, //是否需要填写昵称
      generalize_id: state => state.generalize_id, //推广人id
      business_id: state => state.business_id
    })
  },
  methods: {
    ...mapMutations(["getUser", "getSessionId"]),
    // 点击提交
    doSubmit: debounce(async function() {
      console.log(this.generalize_id,this.business_id,'kkkk');
      this.is_request = true;
      let new_password = this.new_password.replace(/\s+/g, ""),
        confirm_password = this.confirm_password.replace(/\s+/g, "");
      if (!this.nickName && this.nameCode) {
        this.show_layer("cancel", "请输入你的姓名");
        this.is_request = false;
        return;
      }
      if (!new_password) {
        this.show_layer("cancel", "请输入账号密码");
        this.is_request = false;
        return;
      }
      if (!confirm_password) {
        this.show_layer("cancel", "请输入确认密码");
        this.is_request = false;
        return;
      }
      if (new_password != confirm_password) {
        this.show_layer("cancel", "两次输入不一致");
        this.is_request = false;
        return;
      }
      try {
        if (this.businessType === 3) {
          const res = await register(
            this.$route.query.mobile,
            md5(new_password),
            md5(confirm_password),
            this.nickName
          );
          this.is_request = false;
          if (res.code === 0) {
            this.mobile = "";
            this.show_layer("success", "密码设置成功");
            const {PHPSESSID, user} = res.data;
            const show = res.data.is_show
            if (show) {
              sessionStorage.setItem('show', show)
            } else {
              sessionStorage.setItem('show', '')
            }
            SID = PHPSESSID;
            this.getSessionId(PHPSESSID);
            await this.getUser(user);
            await this.getThemeColor();
            try {
              const data = await this.$store.dispatch("getBusinessRate");
              if (JSON.stringify(data) !== "{}") {
                this.$router.replace("home");
              }
            } catch (e) {}
          } else {
            this.show_layer("cancel", res.msg);
          }
        } else {
          let businessInfo = JSON.parse(window.localStorage.getItem('businessInfo'));
          let business_id = this.business_id||businessInfo.id
          const res = this.business_id
            ? await register2(
                this.$route.query.mobile,
                md5(new_password),
                md5(confirm_password),
                this.nickName,
                business_id,
                this.generalize_id
              )
            : await register2(
                this.$route.query.mobile,
                md5(new_password),
                md5(confirm_password),
                this.nickName,
                business_id,
                this.generalize_id
              );
          this.is_request = false;
          if (res.code === 0) {
            this.mobile = "";
            this.show_layer("success", "密码设置成功");
            const {PHPSESSID, user} = res.data;
            const show = res.data.is_show
            if (show) {
              sessionStorage.setItem('show', show)
            } else {
              sessionStorage.setItem('show', '')
            }
            SID = PHPSESSID;
            this.getSessionId(PHPSESSID);
            await this.getUser(user);
            await this.getThemeColor();
            try {
              const data = await this.$store.dispatch("getBusinessRate");
              if (JSON.stringify(data) !== "{}") {
                this.$router.replace("home");
              }
            } catch (e) {}
          } else {
            this.show_layer("cancel", res.msg);
          }
        }
      } catch (error) {
        console.warn("Something bad happened: ", error);
      }
    }, 300),
    // 获取主题色
    async getThemeColor() {
      try {
        const res = await getColor(this.$store.state.userInfo.business_id);
        if (res.code === 0) {
          const { color } = res.data;
          document.body.style.setProperty("--main-color", color);
        }
      } catch (error) {
        console.warn("Something bad happened in api getColor: ", error);
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.page {
  .bj(#fff);

  .img-bg {
    .wh(100%, 3.64rem);
    .bis("../../assets/img_denglu_bg");
  }

  .tab-list {
    position: relative;
    .wh(100%, 1rem);
    margin-bottom: 0.4rem;
    overflow: hidden;

    li {
      .wh(50%, 100%);
      float: left;
      position: relative;
      overflow: hidden;
      z-index: 0;

      .btn {
        position: relative;
        .wh(100%, 100%);
        .sc(@fontsize-medium, @color-grey);
        line-height: 0.4rem;
        text-align: center;
      }

      &.active {
        .btn {
          .sc(@fontsize-large-x, @color-dark-grey);
          line-height: 0.5rem;
          font-weight: bold;

          &:after {
            content: "";
            .cl;
            bottom: 0;
            display: block;
            .wh(2rem, 0.04rem);
            background-color: var(--main-color);
            border-radius: 0.03rem;
          }
        }
      }
    }
  }

  .title {
    padding-left: 0.52rem;
    margin-bottom: 0.18rem;
    .sc(@fontsize-large-xxx, @color-dark-grey);
    line-height: 0.66rem;
    font-weight: bold;
  }

  .form {
    width: 100%;
    padding-left: 0.56rem;
    padding-right: 0.54rem;

    .form-group {
      position: relative;
      display: flex;
      align-items: center;
      padding-top: 0.4rem;
      padding-bottom: 0.3rem;
      box-sizing: border-box;
      z-index: 1;

      .form-label {
        position: absolute;
        top: 0.39rem;
        left: 0;
        .sc(@fontsize-medium, @color-dark-grey);
        line-height: 0.4rem;
        z-index: 4;

        .icon {
          display: inline-block;
          .wh(0.32rem, 0.32rem);
        }

        .icon-password {
          width: 0.28rem;
          .bis("../../assets/icon_denglu_mima");
        }
      }

      .form-control {
        position: relative;
        .wh(100%, 98%);
        padding-left: 0.48rem;
        border: none;
        box-sizing: border-box;
        .sc(@fontsize-medium, @color-dark-grey);
        line-height: 0.4rem;
        transition: 0.3s;
        z-index: 3;

        &::-webkit-input-placeholder {
          color: #b3b3b3;
        }
      }
    }
  }

  .form-bottom {
    position: relative;
    display: flex;
    height: 0.34rem;
    padding-left: 0.56rem;
    padding-right: 0.54rem;
    margin-top: 0.2rem;
    margin-bottom: 1.7rem;

    .tip {
      .sc(@fontsize-small, @color-light-grey);
      line-height: 0.34rem;
      white-space: nowrap;
    }
  }

  .submit-btn {
    .wh(6.4rem, 0.88rem);
    margin: 0 auto;
    .sc(@fontsize-large, #fff);
    line-height: 0.88rem;
    text-align: center;
    white-space: nowrap;
    background-color: var(--main-color);
    border-radius: 0.5rem;
    overflow: hidden;
    .touch-active(var(--main-color));
  }
}
</style>
