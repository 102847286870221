var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.is_generalize == 1
    ? _c(
        "div",
        { staticClass: "page df fdc jc-sb" },
        [
          _c("div", { staticClass: "img-box" }, [
            _vm.background_img_url
              ? _c("img", {
                  staticClass: "img-bg",
                  attrs: { src: _vm.background_img_url, alt: "" },
                })
              : _c("img", {
                  staticClass: "img-bg",
                  attrs: {
                    src: require("../../assets/img_denglu_bg@3x.png"),
                    alt: "",
                  },
                }),
            _c(
              "div",
              {
                staticClass: "collect-icon",
                on: {
                  click: function ($event) {
                    return _vm.backHome()
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/icon_shangpin_home@2x.png"),
                    alt: "",
                  },
                }),
              ]
            ),
            _c("h3", { staticClass: "title" }, [_vm._v("Hi，请注册账号")]),
            _c("div", { staticClass: "form" }, [
              _c("div", { staticClass: "form-group border-bottom-1px" }, [
                _vm._m(0),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.mobile,
                      expression: "mobile",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "tel",
                    required: "",
                    id: "mobile",
                    maxlength: "11",
                    placeholder: "请输入手机号码",
                  },
                  domProps: { value: _vm.mobile },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.mobile = $event.target.value
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "form-group border-bottom-1px" }, [
                _vm._m(1),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.code,
                      expression: "code",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    required: "",
                    id: "code",
                    maxlength: "6",
                    placeholder: "请输入验证码",
                  },
                  domProps: { value: _vm.code },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.code = $event.target.value
                    },
                  },
                }),
                _c(
                  "div",
                  {
                    staticClass: "img-box",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.getRandomImgCode.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "img-code",
                      attrs: { src: _vm.img_code },
                    }),
                  ]
                ),
              ]),
              _c("div", { staticClass: "form-group border-bottom-1px" }, [
                _vm._m(2),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.sms_code,
                      expression: "sms_code",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    required: "",
                    id: "code",
                    maxlength: "6",
                    placeholder: "请输入短信验证码",
                  },
                  domProps: { value: _vm.sms_code },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.sms_code = $event.target.value
                    },
                  },
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.code_bool,
                        expression: "code_bool",
                      },
                    ],
                    staticClass: "btn send-btn flex-center",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.getSmsCode.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("\n          发送验证码\n        ")]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.code_bool,
                        expression: "!code_bool",
                      },
                    ],
                    staticClass: "countdown flex-center",
                  },
                  [_vm._v("\n          " + _vm._s(_vm.count) + "s\n        ")]
                ),
              ]),
            ]),
            _c("div", { staticClass: "form-bottom" }, [
              _c(
                "div",
                {
                  staticClass: "protocol-wrap",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _vm.hasAgree
                    ? _c(
                        "div",
                        {
                          staticClass: "icon-wrap flex-center",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.toggleAgree.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("icon-svg", {
                            staticClass: "selected-item main-color",
                            attrs: { "icon-class": "yi_icon_xuanze" },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "icon-wrap flex-center",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.toggleAgree.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("icon-svg", {
                            staticClass: "select-item",
                            attrs: { "icon-class": "yi_check" },
                          }),
                        ],
                        1
                      ),
                  _c("span", { staticClass: "gray-txt" }, [
                    _vm._v("我已仔细阅读"),
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "protocol",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.openProtocolAlert.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("《用户注册协议》")]
                  ),
                ]
              ),
            ]),
            _vm.is_show == 1
              ? _c("div", { staticClass: "nickname" }, [
                  _vm._v("推广人:" + _vm._s(_vm.nickname)),
                ])
              : _vm._e(),
          ]),
          _c("div", [
            !_vm.is_request
              ? _c(
                  "div",
                  {
                    staticClass: "btn submit-btn flex-center",
                    on: { click: _vm.submit },
                  },
                  [_vm._v("\n      立即注册\n    ")]
                )
              : _c("div", { staticClass: "btn submit-btn flex-center" }, [
                  _vm._v("立即注册"),
                ]),
            _c("p", { staticClass: "login-text" }, [
              _vm._v(_vm._s(_vm.login_text)),
            ]),
          ]),
          _c(
            "transition",
            { attrs: { name: "fade" } },
            [
              _c("opacity-mask", {
                model: {
                  value: _vm.show_mask,
                  callback: function ($$v) {
                    _vm.show_mask = $$v
                  },
                  expression: "show_mask",
                },
              }),
            ],
            1
          ),
          _c("transition", { attrs: { name: "slide-top" } }, [
            _vm.show_protocol
              ? _c("div", { staticClass: "alert-container protocol-alert" }, [
                  _c("div", { staticClass: "alert-content" }, [
                    _c(
                      "div",
                      { staticClass: "protocol-container flex-center" },
                      [
                        _c(
                          "div",
                          { staticClass: "protocol-box" },
                          [
                            _c("Agreement"),
                            _c("article", {
                              staticClass: "protocol-content",
                              domProps: { innerHTML: _vm._s(_vm.contents) },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c("div", {
                      staticClass: "btn close-btn",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.closeProtocol.apply(null, arguments)
                        },
                      },
                    }),
                  ]),
                ])
              : _vm._e(),
          ]),
          _c("alert-tip", {
            attrs: {
              type: _vm.type,
              "show-alert": _vm.show_tip,
              "alert-text": _vm.tip,
            },
            on: { changeShowAlert: _vm.changeShow },
          }),
        ],
        1
      )
    : _c("div", { staticClass: "no-page" })
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "form-label", attrs: { for: "mobile" } },
      [_c("i", { staticClass: "icon icon-mobile" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label", attrs: { for: "code" } }, [
      _c("i", { staticClass: "icon icon-img-code" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label", attrs: { for: "code" } }, [
      _c("i", { staticClass: "icon icon-sms-code" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }