var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("div", { staticClass: "img-bg" }),
      _c("ul", { staticClass: "tab-list" }, [
        _c(
          "li",
          { class: { active: _vm.$route.name === "login" } },
          [
            _c(
              "router-link",
              {
                staticClass: "btn login-btn flex-center",
                attrs: { to: "login", tag: "div" },
              },
              [_vm._v("登录")]
            ),
          ],
          1
        ),
        _c(
          "li",
          {
            class: {
              active:
                _vm.$route.name === "register" ||
                _vm.$route.name === "register_set_password",
            },
          },
          [
            _c(
              "router-link",
              {
                staticClass: "btn register-btn flex-center",
                attrs: { to: "register", tag: "div" },
              },
              [_vm._v("注册")]
            ),
          ],
          1
        ),
      ]),
      _c("h3", { staticClass: "title" }, [_vm._v("请设置账户密码")]),
      _c("div", { staticClass: "form" }, [
        _vm.nameCode
          ? _c("div", { staticClass: "form-group border-bottom-1px" }, [
              _c(
                "label",
                { staticClass: "form-label", attrs: { for: "new_password" } },
                [
                  _c("yd-icon", {
                    attrs: { name: "ucenter", color: "#999", size: "0.4rem" },
                  }),
                ],
                1
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.nickName,
                    expression: "nickName",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  required: "",
                  id: "nike_name",
                  placeholder: "请输入你的姓名",
                },
                domProps: { value: _vm.nickName },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.nickName = $event.target.value
                  },
                },
              }),
            ])
          : _vm._e(),
        _c("div", { staticClass: "form-group border-bottom-1px" }, [
          _vm._m(0),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.new_password,
                expression: "new_password",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "password",
              required: "",
              id: "new_password",
              placeholder: "请设置你的账户密码",
            },
            domProps: { value: _vm.new_password },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.new_password = $event.target.value
              },
            },
          }),
        ]),
        _c("div", { staticClass: "form-group border-bottom-1px" }, [
          _vm._m(1),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.confirm_password,
                expression: "confirm_password",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "password",
              required: "",
              id: "confirm_password",
              placeholder: "请再次输入账户密码",
            },
            domProps: { value: _vm.confirm_password },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.confirm_password = $event.target.value
              },
            },
          }),
        ]),
      ]),
      _vm._m(2),
      !_vm.is_request
        ? _c(
            "div",
            {
              staticClass: "btn submit-btn flex-center",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.doSubmit.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n    确定\n  ")]
          )
        : _c(
            "div",
            {
              staticClass: "btn submit-btn flex-center",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.doSubmit.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n    确定\n  ")]
          ),
      _c("alert-tip", {
        attrs: {
          type: _vm.type,
          "show-alert": _vm.show_tip,
          "alert-text": _vm.tip,
        },
        on: { changeShowAlert: _vm.changeShow },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "form-label", attrs: { for: "new_password" } },
      [_c("i", { staticClass: "icon icon-password" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "form-label", attrs: { for: "confirm_password" } },
      [_c("i", { staticClass: "icon icon-password" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-bottom" }, [
      _c("p", { staticClass: "tip" }, [
        _vm._v("注：账户密码在后台网站登录会用到哦"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }