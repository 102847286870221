var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("div", { staticClass: "form" }, [
        _c("div", { staticClass: "form-group border-bottom-1px" }, [
          _vm._m(0),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.mobile,
                expression: "mobile",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              pattern: "\\d*",
              required: "",
              id: "mobile",
              maxlength: "11",
              placeholder: "请输入手机号码",
            },
            domProps: { value: _vm.mobile },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.mobile = $event.target.value
              },
            },
          }),
        ]),
        _c("div", { staticClass: "form-group border-bottom-1px" }, [
          _vm._m(1),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.new_password,
                expression: "new_password",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "password",
              required: "",
              id: "new_password",
              placeholder: "请设置你的账户密码",
            },
            domProps: { value: _vm.new_password },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.new_password = $event.target.value
              },
            },
          }),
        ]),
        _c("div", { staticClass: "form-group border-bottom-1px" }, [
          _vm._m(2),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.confirm_password,
                expression: "confirm_password",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "password",
              required: "",
              id: "confirm_password",
              placeholder: "请再次输入账户密码",
            },
            domProps: { value: _vm.confirm_password },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.confirm_password = $event.target.value
              },
            },
          }),
        ]),
        _c("div", { staticClass: "form-group border-bottom-1px" }, [
          _vm._m(3),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.code,
                expression: "code",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              required: "",
              id: "imgCode",
              maxlength: "6",
              placeholder: "请输入验证码",
            },
            domProps: { value: _vm.code },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.code = $event.target.value
              },
            },
          }),
          _c(
            "div",
            {
              staticClass: "img-box",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.getRandomImgCode.apply(null, arguments)
                },
              },
            },
            [
              _c("img", {
                staticClass: "img-code",
                attrs: { src: _vm.img_code },
              }),
            ]
          ),
        ]),
        _c("div", { staticClass: "form-group border-bottom-1px" }, [
          _vm._m(4),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.sms_code,
                expression: "sms_code",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              required: "",
              id: "code",
              maxlength: "6",
              placeholder: "请输入短信验证码",
            },
            domProps: { value: _vm.sms_code },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.sms_code = $event.target.value
              },
            },
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.code_bool,
                  expression: "code_bool",
                },
              ],
              staticClass: "btn send-btn flex-center",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.getSmsCode.apply(null, arguments)
                },
              },
            },
            [_vm._v("发送验证码")]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.code_bool,
                  expression: "!code_bool",
                },
              ],
              staticClass: "countdown flex-center",
            },
            [_vm._v(_vm._s(_vm.count) + "s")]
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "btn next-btn flex-center",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.doNext.apply(null, arguments)
            },
          },
        },
        [_vm._v("下一步")]
      ),
      _c("alert-tip", {
        attrs: {
          type: _vm.type,
          "show-alert": _vm.show_tip,
          "alert-text": _vm.tip,
        },
        on: { changeShowAlert: _vm.changeShow },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "form-label", attrs: { for: "mobile" } },
      [_c("i", { staticClass: "icon icon-mobile" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "form-label", attrs: { for: "new_password" } },
      [_c("i", { staticClass: "icon icon-password" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "form-label", attrs: { for: "confirm_password" } },
      [_c("i", { staticClass: "icon icon-password" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label", attrs: { for: "code" } }, [
      _c("i", { staticClass: "icon icon-img-code" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label", attrs: { for: "code" } }, [
      _c("i", { staticClass: "icon icon-sms-code" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }