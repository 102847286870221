var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page df fdc", class: { "jc-sb": !_vm.isDaDiSign } },
    [
      _c("div", [
        _vm.login_img
          ? _c("img", {
              staticClass: "img-bg",
              attrs: { src: _vm.login_img, alt: "" },
            })
          : !_vm.login_img && _vm.img_request
          ? _c("img", {
              staticClass: "img-bg",
              attrs: {
                src: require("../../assets/img_denglu_bg@3x.png"),
                alt: "",
              },
            })
          : _vm._e(),
        _vm.registerStatus == 1 &&
        _vm.businessType != 3 &&
        !_vm.isDaDiSign &&
        !_vm.isChaoWei &&
        !_vm.isJyFl
          ? _c("ul", { staticClass: "tab-list" }, [
              _c(
                "li",
                { class: { active: _vm.$route.name === "login" } },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn login-btn flex-center",
                      attrs: { to: "login", tag: "div" },
                    },
                    [_vm._v("登录")]
                  ),
                ],
                1
              ),
              _c(
                "li",
                {
                  class: {
                    active:
                      _vm.$route.name === "register" ||
                      _vm.$route.name === "register_set_password",
                  },
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn register-btn flex-center",
                      attrs: { to: "register", tag: "div" },
                    },
                    [_vm._v("注册")]
                  ),
                ],
                1
              ),
            ])
          : _c("ul", { staticClass: "tab-list" }),
        !_vm.isDaDiSign
          ? _c("h3", { staticClass: "title" }, [_vm._v("Hi，请登录")])
          : _vm._e(),
        !_vm.isDaDiSign
          ? _c("div", { staticClass: "form" }, [
              _vm.customAccount || _vm.pointsTopic
                ? _c("div", { staticClass: "cell" }, [
                    _c("i", { staticClass: "icon icon-mobile" }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.seaName,
                          expression: "seaName",
                        },
                      ],
                      attrs: { type: "number", placeholder: _vm.accountTxt },
                      domProps: { value: _vm.seaName },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.seaName = $event.target.value
                        },
                      },
                    }),
                  ])
                : _c("div", { staticClass: "cell" }, [
                    _c("i", { staticClass: "icon icon-mobile" }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.mobile,
                          expression: "mobile",
                        },
                      ],
                      attrs: {
                        type: "number",
                        placeholder: _vm.accountTxt,
                        maxlength: "11",
                      },
                      domProps: { value: _vm.mobile },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.mobile = $event.target.value
                        },
                      },
                    }),
                  ]),
              _vm.loginStatus
                ? _c("div", { staticClass: "cell" }, [
                    _c("i", { staticClass: "icon icon-password" }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password",
                        },
                      ],
                      attrs: {
                        type: "password",
                        placeholder: "请输入账户密码",
                      },
                      domProps: { value: _vm.password },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.password = $event.target.value
                        },
                      },
                    }),
                  ])
                : _c("div", [
                    !_vm.isDaDiSign
                      ? _c("div", { staticClass: "cell" }, [
                          _c("i", { staticClass: "icon icon-img-code" }),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.code,
                                expression: "code",
                              },
                            ],
                            attrs: {
                              type: "text",
                              maxlength: "6",
                              placeholder: "请输入验证码",
                            },
                            domProps: { value: _vm.code },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.code = $event.target.value
                              },
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "img-box",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.getRandomImgCode.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "img-code",
                                attrs: { src: _vm.img_code },
                              }),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    !_vm.isDaDiSign
                      ? _c("div", { staticClass: "cell" }, [
                          _c("i", { staticClass: "icon icon-sms-code" }),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.sms_code,
                                expression: "sms_code",
                              },
                            ],
                            attrs: {
                              type: "number",
                              maxlength: "6",
                              placeholder: "请输入短信验证码",
                            },
                            domProps: { value: _vm.sms_code },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.sms_code = $event.target.value
                              },
                            },
                          }),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.code_bool,
                                  expression: "code_bool",
                                },
                              ],
                              staticClass: "btn send-btn flex-center",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.getSmsCode.apply(null, arguments)
                                },
                              },
                            },
                            [_vm._v("\n            发送验证码\n          ")]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.code_bool,
                                  expression: "!code_bool",
                                },
                              ],
                              staticClass: "countdown flex-center",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.count) +
                                  "s\n          "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]),
            ])
          : _vm._e(),
        !_vm.isDaDiSign && !_vm.isChaoWei && !_vm.isJyFl
          ? _c(
              "div",
              { staticClass: "form-bottom" },
              [
                _vm.btnStatus
                  ? _c(
                      "p",
                      {
                        staticClass: "login-status",
                        on: { click: _vm.loginType },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.loginStatus ? "手机快捷登录" : "账号登录"
                            ) +
                            "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.loginStatus && !_vm.customAccount && !_vm.pointsTopic
                  ? _c(
                      "router-link",
                      {
                        staticClass: "link forget-pwd-link",
                        attrs: { to: "forget_password" },
                      },
                      [_vm._v("忘记密码？")]
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("div", [
        !_vm.isDaDiSign
          ? _c("div", [
              !_vm.is_request
                ? _c(
                    "div",
                    {
                      staticClass: "btn submit-btn flex-center",
                      on: {
                        click: function ($event) {
                          _vm.pointsTopic || _vm.customAccount
                            ? _vm.topicSubmit()
                            : _vm.submit()
                        },
                      },
                    },
                    [_vm._v("\n        立即登录\n      ")]
                  )
                : _c("div", { staticClass: "btn submit-btn flex-center" }, [
                    _vm._v("立即登录"),
                  ]),
            ])
          : _vm._e(),
        _vm.wxStatus && !_vm.isChaoWei && !_vm.isJyFl
          ? _c(
              "div",
              {
                staticClass: "btn submit-btn flex-center wx-btn",
                class: { "dadi-shape": _vm.isDaDiSign },
                on: { click: _vm.wxLogin },
              },
              [_vm._v("\n      微信登录\n    ")]
            )
          : _vm._e(),
        _c("p", { staticClass: "login-text" }, [
          _vm._v(_vm._s(_vm.login_text)),
        ]),
      ]),
      _c("alert-tip", {
        attrs: {
          type: _vm.type,
          "show-alert": _vm.show_tip,
          "alert-text": _vm.tip,
        },
        on: { changeShowAlert: _vm.changeShow },
      }),
      _vm.isChaoWei
        ? _c("div", { staticStyle: { "text-align": "center" } }, [
            _vm._v("\n    请使用《电子质保卡系统》账号密码登录\n  "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }