var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("HeadNav"),
      _c("div", { staticClass: "form" }, [
        _vm.oldStatus
          ? _c("div", { staticClass: "form-group border-bottom-1px" }, [
              _vm._m(0),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.old_password,
                    expression: "old_password",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "password",
                  required: "",
                  id: "old_password",
                  placeholder: "请输入你的原账户密码",
                },
                domProps: { value: _vm.old_password },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.old_password = $event.target.value
                  },
                },
              }),
            ])
          : _vm._e(),
        _c("div", { staticClass: "form-group border-bottom-1px" }, [
          _vm._m(1),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.new_password,
                expression: "new_password",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "password",
              required: "",
              id: "new_password",
              placeholder: "请设置你的账户密码",
            },
            domProps: { value: _vm.new_password },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.new_password = $event.target.value
              },
            },
          }),
        ]),
        _c("div", { staticClass: "form-group border-bottom-1px" }, [
          _vm._m(2),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.confirm_password,
                expression: "confirm_password",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "password",
              required: "",
              id: "confirm_password",
              placeholder: "请再次输入账户密码",
            },
            domProps: { value: _vm.confirm_password },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.confirm_password = $event.target.value
              },
            },
          }),
        ]),
      ]),
      _vm._m(3),
      _c(
        "div",
        {
          staticClass: "btn submit-btn flex-center",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.doSubmit.apply(null, arguments)
            },
          },
        },
        [_vm._v("完成")]
      ),
      _c("alert-tip", {
        attrs: {
          type: _vm.type,
          "show-alert": _vm.show_tip,
          "alert-text": _vm.tip,
        },
        on: { changeShowAlert: _vm.changeShow },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "form-label", attrs: { for: "old_password" } },
      [_c("i", { staticClass: "icon icon-password" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "form-label", attrs: { for: "new_password" } },
      [_c("i", { staticClass: "icon icon-password" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "form-label", attrs: { for: "confirm_password" } },
      [_c("i", { staticClass: "icon icon-password" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-bottom" }, [
      _c("p", { staticClass: "tip" }, [
        _vm._v("注：账户密码在后台网站登录会用到哦"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }