<template>
  <div class="page">
    <!-- S 表单区 -->
    <div class="form">
      <div class="form-group border-bottom-1px">
        <label for="mobile" class="form-label">
          <i class="icon icon-mobile"></i>
        </label>
        <input
          type="text"
          pattern="\d*"
          required
          v-model="mobile"
          id="mobile"
          class="form-control"
          maxlength="11"
          placeholder="请输入手机号码"
        >
      </div>
	  <div class="form-group border-bottom-1px">
		  <label for="new_password" class="form-label">
			<i class="icon icon-password"></i>
		  </label>
		  <input
			  type="password"
			  required
			  v-model="new_password"
			  id="new_password"
			  class="form-control"
			  placeholder="请设置你的账户密码"
		  >
		</div>
		<div class="form-group border-bottom-1px">
		  <label for="confirm_password" class="form-label">
			<i class="icon icon-password"></i>
		  </label>
		  <input
			  type="password"
			  required
			  v-model="confirm_password"
			  id="confirm_password"
			  class="form-control"
			  placeholder="请再次输入账户密码"
		  >
	  </div>
      <div class="form-group border-bottom-1px">
        <label for="code" class="form-label">
          <i class="icon icon-img-code"></i>
        </label>
        <input
          type="text"
          required
          v-model="code"
          id="imgCode"
          class="form-control"
          maxlength="6"
          placeholder="请输入验证码"
        >
        <div class="img-box" @click.prevent="getRandomImgCode">
          <img :src="img_code" class="img-code">
        </div>
      </div>
      <div class="form-group border-bottom-1px">
        <label for="code" class="form-label">
          <i class="icon icon-sms-code"></i>
        </label>
        <input
          type="text"
          required
          v-model="sms_code"
          id="code"
          class="form-control"
          maxlength="6"
          placeholder="请输入短信验证码"
        >
        <div class="btn send-btn flex-center" v-show="code_bool" @click.prevent="getSmsCode">发送验证码</div>
        <div class="countdown flex-center" v-show="!code_bool">{{count}}s</div>
      </div>
    </div>
    <!-- E 表单区 -->

    <!-- S 下一步按钮 -->
    <div class="btn next-btn flex-center" @click.prevent="doNext">下一步</div>
    <!-- E 下一步按钮 -->

    <!-- S 提示弹窗 -->
    <alert-tip :type="type" :show-alert="show_tip" :alert-text="tip" @changeShowAlert="changeShow"></alert-tip>
    <!-- E 提示弹窗 -->
  </div>
</template>

<script>
import Validator from "@/utils/validator";
import AlertTip from "@/components/common/AlertTip";
import { getImgCode, sendVerifyCode, forgot } from "@/services";
import { toolTip } from "@/mixins";
import { debounce } from "@/utils/debounce";
import md5 from "js-md5";

let base_code = "";
const COMPUTED_TIME = 60;
export default {
  name: "forget_password",
  components: {
    AlertTip
  },
  mixins: [toolTip],
  data() {
    return {
      mobile: "", // 手机号码
      code: "", // 验证码
      img_code: "", // 图形验证码
      sms_code: "", // 短信验证码
	  new_password: "", // 新密码
	  confirm_password: "", // 再次输入密码
      code_bool: true, // 控制显示获取验证码
      count: "", // 倒计时
      timer: null // 计时器
    };
  },
  created() {
    this.loadImgCode();
  },
  methods: {
    // 获取图形验证码
    async loadImgCode() {
      const res = await getImgCode();
      if (res.code === 0) {
        this.img_code = res.data.url;
        base_code = res.data.url;
      } else {
        if (process.env.NODE_ENV === "development") {
          this.show_layer("cancel", res.error_msg);
        }
      }
    },
    // 切换图形验证码
    getRandomImgCode: debounce(function() {
      this.img_code = base_code + "&" + Math.random();
    }, 300),
    // 获取短信验证码
    getSmsCode: debounce(async function() {
      let mobile = this.mobile.replace(/\s+/g, ""),
        code = this.code.replace(/\s+/g, "");
      const { result, errorMessage } = Validator.validate("mobile", mobile);
      if (!mobile) {
        this.show_layer("cancel", "请输入手机号码");
        return;
      }
      if (!result) {
        this.show_layer("cancel", errorMessage);
        return;
      }
      if (!code) {
        this.show_layer("cancel", "请输入图形验证码");
        return;
      }
      if (this.timer) {
        clearInterval(this.timer);
      }

      const res = await sendVerifyCode(mobile, "login", code);
      if (res.code === 0) {
        this.show_layer("success", "验证码已发送，请注意查收");
        this.count = COMPUTED_TIME;
        this.code_bool = false;
        this.timer = setInterval(() => {
          if (this.count > 0) {
            this.count--;
          } else {
            this.code_bool = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      } else {
		this.show_layer("cancel", res.msg);
	  }
    }, 300),
    // 点击下一步
    doNext: debounce(async function() {
      let mobile = this.mobile.replace(/\s+/g, ""),
        code = this.code.replace(/\s+/g, ""),
        sms_code = this.sms_code.replace(/\s+/g, ""),
		new_password = this.new_password.replace(/\s+/g, ""),
		  confirm_password = this.confirm_password.replace(/\s+/g, "");
      const { result, errorMessage } = Validator.validate("mobile", mobile);
      if (!mobile) {
        this.show_layer("cancel", "请输入手机号码");
        return;
      }
      if (!result) {
        this.show_layer("cancel", errorMessage);
        return;
      }
	  if (!new_password) {
	    this.show_layer("cancel", "请输入账号密码");
	    return;
	  }
	  if (!confirm_password) {
	    this.show_layer("cancel", "请输入确认密码");
	    return;
	  }
	  if (new_password != confirm_password) {
	    this.show_layer("cancel", "两次输入不一致");
	    return;
	  }
      if (!code) {
        this.show_layer("cancel", "请输入图形验证码");
        return;
      }
      if (!sms_code) {
        this.show_layer("cancel", "请输入短信验证码");
        return;
      }
      /* this.$router.push({
        name: "set_password",
        query: {
          code: sms_code,
          mobile:mobile,
        }
      }); */
	  
		try {
			const res = await forgot(sms_code, md5(new_password), md5(confirm_password), mobile);
			if (res.code === 0) {
				this.show_layer('success', '密码设置成功');
				setTimeout(() => {
					this.$router.replace('login');
				}, 1000);
			} else {
				this.show_layer('cancel', res.msg);
			}
		} catch (error) {
			console.warn('Something bad happened: ', error);
		}
    }, 300)
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
};
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.page {
  .bj(#fff);
}

.form {
  width: 100%;
  padding-left: 0.56rem;
  padding-right: 0.54rem;
  margin-bottom: 1.1rem;

  .form-group {
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 0.4rem;
    padding-bottom: 0.3rem;
    box-sizing: border-box;
    z-index: 1;

    .form-label {
      position: absolute;
      top: 0.44rem;
      left: 0;
      .sc(@fontsize-medium, @color-dark-grey);
      line-height: 0.4rem;
      z-index: 4;

      .icon {
        display: inline-block;
        .wh(0.32rem, 0.32rem);
      }

      .icon-mobile {
        width: 0.24rem;
        .bis("../../assets/icon_denglu_shouji");
      }

      .icon-img-code {
        height: 0.28rem;
        .bis("../../assets/icon_denglu_yanzhengma");
      }
	  
	  .icon-password {
	    width: 0.28rem;
	    .bis("../../assets/icon_denglu_mima");
	  }

      .icon-sms-code {
        .bis("../../assets/icon_denglu_shuruyanzhengma");
      }
    }

    .form-control {
      position: relative;
      .wh(100%, 98%);
      padding-left: 0.48rem;
      border: none;
      box-sizing: border-box;
      .sc(@fontsize-medium, @color-dark-grey);
      line-height: 0.4rem;
      transition: 0.3s;
      z-index: 3;

      &::-webkit-input-placeholder {
        color: #b3b3b3;
      }
    }

    .img-box {
      .ct;
      right: 0;
      .wh(2.4rem, 0.8rem);
      z-index: 4;

      .img-code {
        .wh(100%, 100%);
      }
    }

    .send-btn,
    .countdown {
      .ct;
      right: 0;
      .wh(1.68rem, 0.52rem);
      .sc(@fontsize-small, #fff);
      line-height: 0.52rem;
      text-align: center;
      white-space: nowrap;
      border-radius: 0.26rem;
      z-index: 4;
    }

    .send-btn {
      background: var(--main-color);
      .touch-active;
    }

    .countdown {
      background: @color-light-grey;
    }
  }
}

.next-btn {
  .wh(6.4rem, 0.88rem);
  margin: 0 auto;
  .sc(@fontsize-large, #fff);
  line-height: 0.88rem;
  text-align: center;
  white-space: nowrap;
  background: var(--main-color);
  border-radius: 0.5rem;
  .touch-active(var(--main-color));
}
</style>
